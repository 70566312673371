import {Component, OnInit} from '@angular/core';
import {EtGdComponent} from '../et-gd/et-gd.component';

@Component({
  selector: 'app-et-gd-te-pt',
  templateUrl: './et-gd-te-pt.component.html',
  styleUrls: ['./et-gd-te-pt.component.css']
})
export class EtGdTePtComponent extends EtGdComponent implements OnInit {

  ngOnInit() {
    super.onInit('et-gd-te-pt');
  }

}
