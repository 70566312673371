import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AsGdComponent} from '../as-gd/as-gd.component';

@Component({
  selector: 'app-as-gd-te-te',
  templateUrl: './as-gd-te-te.component.html'
})

export class AsGdTeTeComponent extends AsGdComponent implements OnInit, OnChanges {
  indexInit: number;
  @Input() currentIndex: number;

  ngOnInit() {
    this.indexInit = this.quiz.questions[this.quiz.index].id;
    super.onInit('as-gd-te-te');

    // si random et aucune reponse saisie
    if (typeof this.quiz.questions[this.quiz.index].proposition_random !== 'undefined' &&
      this.quiz.questions[this.quiz.index].proposition_random === '1' && !this.bPropSaisie
    ) {
      this.utilsService.getShuffleProposition(this.propNotChecked);
    }

    // si alphaNum et aucune reponse saisie
    if (typeof this.quiz.questions[this.quiz.index].proposition_alpha_num !== 'undefined' &&
      this.quiz.questions[this.quiz.index].proposition_alpha_num === '1' && !this.bPropSaisie
    ) {
      this.utilsService.getSortAlphaNum(this.propNotChecked);
    }
  }

  // hack ngOnChanges ne bind pas le changement
  // qd 2 et-gd se suive enchainement le composant n est pas detruit et on ne repasse pas dans le ngonint
  // ngDoCheck(): void {
  //   if (this.quiz.questions[this.quiz.index].id != this.indexInit) {
  //     this.ngOnInit();
  //   }
  // }

  ngOnChanges(changes: SimpleChanges) {
    if (this.currentIndex !== this.indexInit) {
      this.indexInit = this.currentIndex;
      this.ngOnInit();
    }
  }

}
