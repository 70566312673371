import {RouterModule, Routes} from '@angular/router';
import {NavigatorContentComponent} from './pages/navigator.component';

const routes: Routes = [
  {
    path: '',
    component: NavigatorContentComponent
    // canActivate: [AuthGuard]
  }
];

export const ROUTES = RouterModule.forRoot(routes);
