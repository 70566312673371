export class Config {
  instance: string;
  mode: string;
  version: string;
  apiUrl: string;
  repository_url: string;
  title: string;
  port: number;
  ips: any[];
  path_medias: string;
  demat: boolean;
  calc: boolean;
  help: boolean;
  progress_bar: boolean;
  chrono: boolean;
  chrono_time: number;
  langue: string;
  territory: string;
  retry: boolean;
  questionPrevious: boolean;
  labelPrevious: string;
  corrections: boolean;
  btnValidateNone: boolean;
  labelValidateNone: string;
  question_url: string;
  quizUrl: string;
  browser: string;

  constructor() {
    this.instance = '';
    this.mode = '';
    this.version = '';
    this.apiUrl = '';
    this.repository_url = '';
    this.title = '';
    this.port = 0;
    this.ips = null;
    this.path_medias = '';
    this.demat = false;
    this.help = false;
    this.progress_bar = false;
    this.calc = false;
    this.chrono = false;
    this.chrono_time = 0;
    this.langue = '';
    this.territory = '';
    this.retry = false;
    this.questionPrevious = false;
    this.labelPrevious = '';
    this.corrections = false;
    this.btnValidateNone = true;
    this.labelValidateNone = '';
    this.question_url = '';
    this.quizUrl = '';
    this.browser = '';
  }
}
