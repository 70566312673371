import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  templateUrl: './modal-viewer.component.html'
})
export class ModalViewerComponent implements OnInit {

  message = '';
  title = '';

  constructor(public dialogRef: MatDialogRef<ModalViewerComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.message = this.data.message;
    this.title = this.data.title;
  }

}
