import {Component, EventEmitter, HostListener, OnInit, Output} from '@angular/core';
import {CalculatorService} from '../../services/service.calculator';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.css']
})
export class CalculatorComponent implements OnInit {

  public display;
  public bottomDisplay;
  public ios: boolean;
  // envoi au parent la methode
  @Output()
  calculatorToggle: EventEmitter<string> = new EventEmitter();
  inverse = function () {
    this.display = this.calculatorService.inverse();
  };
  private calculatorService: CalculatorService;

  constructor() {
  }

  ngOnInit() {
    this.calculatorService = new CalculatorService();
    this.ios = /iPad|iPhone|iPod/.test(navigator.userAgent);
  }

  close() {
    this.calculatorToggle.emit();
  }

  input(n) {
    this.display = this.calculatorService.input(n);
  }

  calculate() {
    this.bottomDisplay = this.calculatorService.calculate();
  }

  reset() {
    this.calculatorService.reset();
    this.display = '';
    this.bottomDisplay = '';
  }

  cancel() {
    this.display = this.calculatorService.cancel();
    this.bottomDisplay = '';
  }

  @HostListener('window:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    // console.log(e.which + ' --> ' + e.key);
    switch (event.which) {
      case 48: // 0
      case 49: // 1
      case 50: // 2
      case 51: // 3
      case 52: // 4
      case 53: // 5
      case 54: // 6
      case 55: // 7
      case 56: // 8
      case 57: // 9
      case 43: // +
      case 45: // -
      case 42: // *
      case 47: // /
      case 46: // .
        this.input(event.key);
        // $scope.$apply();
        break;
      case 13: // enter
        this.calculate();
        event.preventDefault();
        break;

      default:
    }

  }
}
