import {Component, Input} from '@angular/core';
import {UtilsService} from '../../../services/service.utils';

@Component({
  selector: 'de',
  templateUrl: './de.component.html'
})
export class DeComponent {
  // recupere le quiz
  @Input() quiz: any;
  @Input() currentIndex: number;

  constructor(protected utilsService: UtilsService) {
  }

  // si une proposition est posee
  updateNextButton() {
    const propositions = (Array.isArray(this.quiz.questions[this.quiz.index].propositions)) ? this.quiz.questions[this.quiz.index].propositions : [this.quiz.questions[this.quiz.index].propositions];
    let flag = false;
    for (const prop of propositions) {
      if (prop.checked) {
        flag = true;
        break;
      }
    }
    this.quiz.next = flag;
  };
}
