import {AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';

/*const screenfull = require('screenfull');


@Directive({
  selector: '[myHighlight]'
})
export class HighlightDirective {

  constructor(private el: ElementRef) {
  }

  @Input('myHighlight') highlightColor: string;
  @Input() defaultColor: string;

  @HostListener('mouseenter') onMouseEnter() {
    this.highlight(this.highlightColor || this.defaultColor || 'red');
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.highlight(null);
  }


  private highlight(color: string) {
    this.el.nativeElement.style.backgroundColor = color;
  }
}*/

@Directive({
  selector: '[sglclick]',
})
export class SglclickDirective {
  delay = 300;
  clicks = 0;
  timer = null;

  @Output() sglclickEmitter: EventEmitter<string> = new EventEmitter();

  @HostListener('click') onClick() {
    this.clicks++;  // count clicks
    if (this.clicks === 1) {
      this.timer = setTimeout(() => {
        this.clicks = 0;
        // on appelle la fonction depuis le html
        this.sglclickEmitter.emit();
      }, this.delay);
    } else {
      clearTimeout(this.timer);    // prevent single-click action
      this.clicks = 0;             // after action performed, reset counter
    }
  }
}

@Directive({
  selector: '[dblclickListener]',
})
export class DblclickListenerDirective {
  delay = 350;
  clicks = 0;
  timer = null;
  isSingleClick = true;

  @Output() dblclickListenerEmitter: EventEmitter<string> = new EventEmitter();

  @HostListener('click') onClick() {
    this.clicks++;  // count clicks
    if (this.clicks === 1) {
      this.timer = setTimeout(() => {
        if (this.isSingleClick) {
          this.clicks = 0;
        }
      }, this.delay);
    } else if (this.clicks === 2) {
      this.isSingleClick = false;
      this.timer = setTimeout(() => {
        this.clicks = 0;
        // on appelle la fonction depuis le html
        this.dblclickListenerEmitter.emit();
        this.isSingleClick = true;
      }, this.delay);
    } else if (this.clicks > 2) {
      clearTimeout(this.timer);    // prevent single-click action
      this.clicks = 0;             // after action performed, reset counter
    }
  }
}

// permet de supprimer les commentaires generes par des boucles
@Directive({
  selector: '[removeChildren]',
})
export class RemoveChildrenDirective implements AfterViewInit {
  @Output() removeChildrenEmitter: EventEmitter<string> = new EventEmitter();
  elt: any = null;

  constructor(el: ElementRef) {
    this.elt = el;
  }

  ngAfterViewInit() {
    if (typeof this.elt.nativeElement.childNodes !== 'undefined' && typeof this.elt.nativeElement.childNodes[0] !== 'undefined') {
      // cas d'une proposition vide
      if (typeof this.elt.nativeElement.childNodes[1] !== 'undefined' && this.elt.nativeElement.childNodes[1].id.indexOf('undefined') >= 0) {
        while (this.elt.nativeElement.firstChild) {
          this.elt.nativeElement.removeChild(this.elt.nativeElement.firstChild);
        }
      } else {
        // cas d'une cible vide
        if (typeof this.elt.nativeElement.childNodes[1] !== 'undefined' && this.elt.nativeElement.childNodes[1].id.indexOf('cibleRg') >= 0 && this.elt.nativeElement.childNodes[1].innerHTML.indexOf('proposition') <= 0) {
          this.elt.nativeElement.childNodes[1].innerHTML = '';
        }
      }
    }
  }
}

@Directive({selector: '[mouseWheel]'})
export class MouseWheelDirective {
  @Output() mouseWheelUp = new EventEmitter();
  @Output() mouseWheelDown = new EventEmitter();

  @HostListener('mousewheel', ['$event']) onMouseWheelChrome(event: any) {
    this.mouseWheelFunc(event);
  }

  @HostListener('DOMMouseScroll', ['$event']) onMouseWheelFirefox(event: any) {
    this.mouseWheelFunc(event);
  }

  @HostListener('onmousewheel', ['$event']) onMouseWheelIE(event: any) {
    this.mouseWheelFunc(event);
  }

  mouseWheelFunc(event: any) {
    event = window.event || event; // old IE support
    const delta = Math.max(-1, Math.min(1, (event.wheelDelta || -event.detail)));
    if (delta > 0) {
      this.mouseWheelUp.emit(event);
    } else if (delta < 0) {
      this.mouseWheelDown.emit(event);
    }
    // for IE
    event.returnValue = false;
    // for Chrome and Firefox
    if (event.preventDefault) {
      event.preventDefault();
    }
  }
}
