import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AsGdComponent} from '../as-gd/as-gd.component';

@Component({
  selector: 'app-as-gd-im-im',
  templateUrl: './as-gd-im-im.component.html'
})

export class AsGdImImComponent extends AsGdComponent implements OnInit, OnChanges {
  indexInit: number;
  @Input() currentIndex: number;

  ngOnInit() {
    this.indexInit = this.quiz.questions[this.quiz.index].id;
    super.onInit('as-gd-im-im');
  }

  // hack ngOnChanges ne bind pas le changement
  // qd 2 et-gd se suive enchainement le composant n est pas detruit et on ne repasse pas dans le ngonint
  // ngDoCheck(): void {
  //   if(this.quiz.questions[this.quiz.index].id != this.indexInit) {
  //     this.ngOnInit();
  //   }
  // }

  ngOnChanges(changes: SimpleChanges) {
    if (this.currentIndex !== this.indexInit) {
      this.indexInit = this.currentIndex;
      this.ngOnInit();
    }
  }

}
