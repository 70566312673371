import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({name: 'thumb'})
export class UtilsPipe implements PipeTransform {
  transform(value, extension): string {
    let filename = value.filename;
    filename = filename.split('.');
    filename[filename.length - 2] += '_thumb';
    if (typeof extension !== 'undefined') {
      filename[filename.length - 1] = extension;
      // les miniatures sont en png dans mirolearning... ce parametre supplémentaire permet de rester générique
    }
    return filename.join('.');
  }
}


@Pipe({name: 'isTruePipe'})
export class IsTruePipe implements PipeTransform {
  transform(input: any, field: string, value: boolean): any {
    const arrReturn: any = [];
    for (const t of input) {
      if (t[field] === value) {
        arrReturn.push(t);
      }
    }
    return arrReturn;
  }
}

@Pipe({name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {
  }

  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

@Pipe({name: 'filterEquals'})
export class FilterEqualsPipe implements PipeTransform {
  transform(input: any, field: string, value: number): any {
    const arrReturn: any = [];
    for (const t of input) {
      if (t[field].toString() === value.toString()) {
        arrReturn.push(t);
      }
    }
    return arrReturn;
  }
}
