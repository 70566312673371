import {Component, EventEmitter, OnInit, Output} from '@angular/core';
const screenfull = require('screenfull');

@Component({
  selector: 'app-button-fullscreen',
  templateUrl: 'button-fullscreen.component.html',
})

export class ButtonFullscreenComponent implements OnInit {
  isFullscreen: boolean = false;

  // envoi au parent la methode
  @Output()
  setFullScreen: EventEmitter<string> = new EventEmitter();

  constructor() {
  }

  toggleFullscreen() {
    if (screenfull.enabled) {
      // this.isFullscreen = !screenfull.isFullscreen;
      screenfull.toggle();
    }
    this.setFullScreen.emit(screenfull.isFullscreen);
  }

  ngOnInit() {
    // gestion de la sortie plein ecran par le bouton echap
    if (screenfull.enabled) {
      screenfull.onchange(() => {
        this.isFullscreen = screenfull.isFullscreen;
      });
    }

  }
}

