import {Question} from './question';
import {Translate} from './translate';

export class Quiz {
  id: number;
  // type: string; //eprofil,quiz ...
  index: number; // index de la question en cours
  folder: string; // path de la question
  questions: Question[]; // les questions
  translates: Translate[]; // la traduction
  correction: any; // mode correctif ou non
  next: boolean; // passage question suivante ou non
  finished: boolean; // etat du quiz

  constructor() {
    this.id = 0;
    // this.type = '';
    this.index = 0;
    this.folder = '';
    this.questions = [];
    this.translates = [];
    this.correction = null;
    this.next = false;
    this.finished = false;
  }
}
