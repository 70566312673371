import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Quiz} from '../../models/quiz';
import {Subscription} from 'rxjs';
import {ScreenService} from '../../services/service.screenConfig';

@Component({

  selector: 'app-button-dontknow',
  templateUrl: 'button-dontknow.component.html',
})
export class ButtonDontknowComponent implements OnInit {

  screenSubscription: Subscription;
  screenType: string;
  labelDontKnow: string;

  // recupere le quiz et la config
  @Input() quiz: Quiz;
  @Input() config: any;

  // envoi au parent la methode
  @Output() dontknow: EventEmitter<string> = new EventEmitter();

  constructor(private screenService: ScreenService) {
  }

  ngOnInit() {
    // BINDING widthscreen
    this.screenSubscription = this.screenService.screenType$.subscribe(screenType => {
      if (screenType) {
        this.screenType = screenType.toString();
      }
    });

    this.labelDontKnow = (this.config.labelValidateNone && this.config.labelValidateNone !== '') ? this.config.labelValidateNone : 'Je ne sais pas';
  }

  dontknowQuestion() {
    this.dontknow.emit();
  }
}
