import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

const Hammer = require('hammerjs');

// declare var jQuery:any;
let instance: any;

@Component({
  selector: 'app-media-viewer',
  templateUrl: 'media-viewer.component.html',
  styleUrls: ['media-viewer.component.css'],
})

export class ModalMediaComponent implements OnInit {

  MIN_SCALE = 0.2; // 1=scaling when first loaded
  MAX_SCALE = 64;
  imgWidth: number = null;
  imgHeight: number = null;
  viewportWidth: number = null;
  viewportHeight: number = null;
  scale: number = null;
  scaleMin: number = null;
  lastScale: number = null;
  container: any = null;
  img: any = null;
  x = 0;
  lastX = 0;
  y = 0;
  lastY = 0;
  pinchCenter: any = null;
  curWidth = 0;
  curHeight = 0;
  // envoi au parent la methode
  @Output() closeMediaPreview: EventEmitter<string> = new EventEmitter();
  // recupere le quiz
  @Input() imgSrc: String;
  zoomIn = function () {
    this.zoomCenter(1.1);
  };


  // We need to disable the following event handlers so that the browser doesn't try to
  initImage = function () {
    let img1 = new Image();
    img1 = <HTMLImageElement>document.getElementById('pinch-zoom-image-id');
    const instance = this;
    img1.onload = function () {
      document.getElementById('pinch-zoom-container').appendChild(img1);
      instance.setImage(img1);
    };
    img1.src = this.imgSrc;
  };
  displayImage = function (img) {
    img.style.display = 'block';
  };
  setImage = function (imgInit) {
    this.img = document.getElementById('pinch-zoom-image-id');
    this.container = this.img.parentElement;
    this.disableImgEventHandlers();
    this.imgWidth = imgInit.naturalWidth; // imgInit.width;
    this.imgHeight = imgInit.naturalHeight; // imgInit.height;
    this.viewportWidth = imgInit.offsetWidth;
    this.scale = this.viewportWidth / this.imgWidth;
    this.scaleMin = this.scale;
    this.lastScale = this.scale;
    this.viewportHeight = this.img.parentElement.offsetHeight;
    this.curWidth = this.imgWidth * this.scale;
    this.curHeight = this.imgHeight * this.scale;
    this.img.style.display = 'none';

    // cas image plus grande que la zone
    let scaleImgSup = 0;

    let newFormat = false;
    if (this.imgHeight > this.container.offsetHeight - document.getElementById('header').offsetHeight) {
      scaleImgSup = (this.container.offsetHeight - document.getElementById('header').offsetHeight) / this.imgHeight;
      newFormat = true;
    }
    if (this.imgWidth > this.container.offsetWidth) {
      scaleImgSup = (scaleImgSup !== 0 && scaleImgSup < this.container.offsetWidth / this.imgWidth) ? scaleImgSup : this.container.offsetWidth / this.imgWidth;
      newFormat = true;
    }

    if (newFormat) {
      this.zoomCenter(scaleImgSup);
    }

    const hammer = new Hammer(this.img, {
      domEvents: true
    });

    setTimeout(function () {
      instance.displayImage(instance.img);
    }, 100);

    hammer.get('pinch').set({
      enable: true
    });

    hammer.on('pan', function (e) {
      instance.translate(e.deltaX, e.deltaY);
    });

    hammer.on('panend', function (e) {
      instance.updateLastPos();
    });

    hammer.on('pinch', function (e) {
      if (instance.pinchCenter === null) {
        instance.pinchCenter = instance.rawCenter(e);
        const offsetX = instance.pinchCenter.x * instance.scale - (-instance.x * instance.scale + Math.min(instance.viewportWidth, instance.curWidth) / 2);
        const offsetY = instance.pinchCenter.y * instance.scale - (-instance.y * instance.scale + Math.min(instance.viewportHeight, instance.curHeight) / 2);
        instance.pinchCenterOffset = {x: offsetX, y: offsetY};
      }
      const newScale = instance.restrictScale(instance.scale * e.scale);
      const zoomX = instance.pinchCenter.x * newScale - instance.pinchCenterOffset.x;
      const zoomY = instance.pinchCenter.y * newScale - instance.pinchCenterOffset.y;
      const zoomCenter = {x: zoomX / newScale, y: zoomY / newScale};
      instance.zoomAround(e.scale, zoomCenter.x, zoomCenter.y, true);
    });

    hammer.on('pinchend', function (e) {
      instance.updateLastScale();
      instance.updateLastPos();
      instance.pinchCenter = null;
    });

    hammer.on('doubletap', function (e) {
      const c = instance.rawCenter(e);
      instance.zoomAround(2, c.x, c.y);
    });
  };

  constructor() {
    instance = this;
  }

  // automatically handle our image drag gestures.
  disableImgEventHandlers() {
    const events = ['onclick', 'onmousedown', 'onmousemove', 'onmouseout', 'onmouseover', 'onmouseup', 'ondblclick', 'onfocus', 'onblur'];
    events.forEach(function (event) {
      instance.img[event] = function () {
        return false;
      };
    });
  }

  // Traverse the DOM to calculate the absolute position of an element
  absolutePosition(el) {
    let x = 0, y = 0;
    while (el !== null) {
      x += el.offsetLeft;
      y += el.offsetTop;
      el = el.offsetParent;
    }
    return {x: x, y: y};
  }

  restrictScale(scale) {
    if (scale < this.MIN_SCALE) {
      scale = this.MIN_SCALE;
    } else if (scale > this.MAX_SCALE) {
      scale = this.MAX_SCALE;
    }
    return scale;
  }

  updateLastPos(/*deltaX, deltaY */) {
    this.lastX = this.x;
    this.lastY = this.y;
  }

  restrictRawPos(pos, viewportDim, imgDim) {
    // if (pos < viewportDim / this.scale - imgDim) { // too far left/up?
    if (pos < -imgDim) { // too far left/up?
      // pos = (viewportDim / this.scale) - imgDim;
      pos = -imgDim;
    } else if (pos > imgDim) { // too far right/down?
      pos = imgDim;
    }
    return pos;
  }

  translate(deltaX, deltaY) {
    // positionne l'image
    const newX = this.restrictRawPos(this.lastX + deltaX / this.scale,
      Math.min(this.viewportWidth, this.curWidth), this.imgWidth);
    this.x = newX;
    this.img.style.marginLeft = Math.ceil(newX * this.scale) + 'px';
    const newY = this.restrictRawPos(this.lastY + deltaY / this.scale,
      Math.min(this.viewportHeight, this.curHeight), this.imgHeight);
    this.y = newY;
    this.img.style.marginTop = Math.ceil(newY * this.scale) + 'px';
  }

  zoom(scaleBy) {
    this.lastScale = this.lastScale === 0 ? 0.9 : this.lastScale;
    this.scale = this.restrictScale(this.lastScale * scaleBy);
    this.curWidth = this.imgWidth * this.scale;
    this.curHeight = this.imgHeight * this.scale;
    this.img.style.width = Math.ceil(this.curWidth) + 'px';
    this.img.style.height = Math.ceil(this.curHeight) + 'px';
    // Adjust margins to make sure that we aren't out of bounds
    this.translate(0, 0);
  }

  rawCenter(e) {
    const pos = this.absolutePosition(this.container);
    // We need to account for the scroll position
    const scrollLeft = window.pageXOffset ? window.pageXOffset : document.body.scrollLeft;
    const scrollTop = window.pageYOffset ? window.pageYOffset : document.body.scrollTop;
    const zoomX = -this.x + (e.center.x - pos.x + scrollLeft) / this.scale;
    const zoomY = -this.y + (e.center.y - pos.y + scrollTop) / this.scale;
    return {x: zoomX, y: zoomY};
  }

  updateLastScale() {
    this.lastScale = this.scale;
  }

  zoomAround(scaleBy, rawZoomX, rawZoomY, doNotUpdateLast) {
    // Zoom
    this.zoom(scaleBy);
    // New raw center of viewport
    // const rawCenterX = -this.x + Math.min(this.viewportWidth, this.curWidth) / 2 / this.scale;
    // const rawCenterY = -this.y + Math.min(this.viewportHeight, this.curHeight) / 2 / this.scale;
    // Delta
    // const deltaX = (rawCenterX - rawZoomX) * this.scale;
    // const deltaY = (rawCenterY - rawZoomY) * this.scale;
    // Translate back to zoom center
    // this.translate(deltaX, deltaY);
    // fait ulterieurement sur le pinchend
    if (!doNotUpdateLast) {
      this.updateLastScale();
      this.updateLastPos();
    }
  }

  zoomCenter(scaleBy) {
    // Center of viewport
    const zoomX = -this.x + Math.min(this.viewportWidth, this.curWidth) / 2 / this.scale;
    const zoomY = -this.y + Math.min(this.viewportHeight, this.curHeight) / 2 / this.scale;
    this.zoomAround(scaleBy, zoomX, zoomY, false);
  }

  zoomOut() {
    const scaleBy = this.scale <= 0.4 ? 1 : (0.9);
    this.zoomCenter(scaleBy);
  }

  closeMedia() {
    this.closeMediaPreview.emit();
  }

  ngOnInit() {
    this.initImage();
  }

}
