import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {BrowserModule, DomSanitizer} from '@angular/platform-browser';
import {SwiperModule} from 'ngx-swiper-wrapper';
import {FlexLayoutModule} from '@angular/flex-layout';
import {
  MatButtonModule,
  MatCardModule,
  MatDialogModule,
  MatGridListModule,
  MatIconModule,
  MatIconRegistry,
  MatInputModule,
  MatProgressBarModule,
  MatRippleModule,
  MatSelectModule,
  MatSliderModule
} from '@angular/material';
import {AppComponent} from './app.component';
import {FilterEqualsPipe, IsTruePipe, SafeHtmlPipe, UtilsPipe} from './app.pipes';
import {DragulaModule, DragulaService} from 'ng2-dragula';
import {
  DblclickListenerDirective,
  MouseWheelDirective,
  RemoveChildrenDirective,
  SglclickDirective
} from './app.directives';
import {ROUTES} from './app-routing.module';
import {AuthGuard} from './app.guard';
// pages
import {NavigatorContentComponent} from './pages/navigator.component';

// services
import {ConfigService} from './services/service.config';
import {QuizService} from './services/service.quiz';
import {QuestionService} from './services/service.questions';
import {PlatformWrapperService} from './services/service.platform-wrapper';
import {UtilsService} from './services/service.utils';
import {ScreenService} from './services/service.screenConfig';

// component
import {CalculatorComponent} from './components/calculator/calculator.component';
import {ModalMediaComponent} from './components/media-viewer/media-viewer.component';
import {ModalViewerComponent} from './components/modal-viewer/modal-viewer.component';
// buttons
import {ButtonNextComponent} from './components/buttons/button-next.component';
import {ButtonSaveComponent} from './components/buttons/button-save.component';
import {ButtonPreviousComponent} from './components/buttons/button-previous.component';
import {ButtonCalculatorComponent} from './components/buttons/button-calculator.component';
import {ButtonDontknowComponent} from './components/buttons/button-dontknow.component';
import {ButtonHelpComponent} from './components/buttons/button-help.component';
import {ButtonValidateComponent} from './components/buttons/button-validate.component';
import {ButtonFullscreenComponent} from './components/buttons/button-fullscreen.component';
// gabarits
import {DeZcPtPcComponent} from './components/gabarit/de-zc-pt-pc/de-zc-pt-pc.component';
import {OrGdTeRgComponent} from './components/gabarit/or-gd-te-rg/or-gd-te-rg.component';
import {DePsTePcComponent} from './components/gabarit/de-ps-te-pc/de-ps-te-pc.component';
import {DeIcImPcComponent} from './components/gabarit/de-ic-im-pc/de-ic-im-pc.component';
import {AsGdImImComponent} from './components/gabarit/as-gd-im-im/as-gd-im-im.component';
import {EtGdImPtComponent} from './components/gabarit/et-gd-im-pt/et-gd-im-pt.component';
import {CoLdLvIdComponent} from './components/gabarit/co-ld-lv-id/co-ld-lv-id.component';
import {EtGdComponent} from './components/gabarit/et-gd/et-gd.component';
import {EtGdTePtComponent} from './components/gabarit/et-gd-te-pt/et-gd-te-pt.component';
import {AsGdComponent} from './components/gabarit/as-gd/as-gd.component';
import {AsGdTeImComponent} from './components/gabarit/as-gd-te-im/as-gd-te-im.component';
import {AsGdTeTeComponent} from './components/gabarit/as-gd-te-te/as-gd-te-te.component';
import {ReCrNoPcComponent} from './components/gabarit/re-cr-no-pc/re-cr-no-pc.component';
import {ReCrTePcComponent} from './components/gabarit/re-cr-te-pc/re-cr-te-pc.component';
import {DeComponent} from './components/gabarit/de/de.component';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatCardModule,
    MatDialogModule,
    MatSelectModule,
    MatRippleModule,
    MatButtonModule,
    MatGridListModule,
    MatSliderModule,
    MatInputModule,
    MatIconModule,
    MatProgressBarModule,
    SwiperModule,
    FlexLayoutModule,
    DragulaModule,
    ROUTES
  ],
  declarations: [
    UtilsPipe,
    SglclickDirective,
    DblclickListenerDirective,
    MouseWheelDirective,
    RemoveChildrenDirective,
    IsTruePipe,
    FilterEqualsPipe,
    SafeHtmlPipe,
    AppComponent,
    NavigatorContentComponent,
    CalculatorComponent,
    ModalMediaComponent,
    ModalViewerComponent,
    ButtonNextComponent,
    ButtonSaveComponent,
    ButtonPreviousComponent,
    ButtonCalculatorComponent,
    ButtonDontknowComponent,
    ButtonHelpComponent,
    ButtonValidateComponent,
    ButtonFullscreenComponent,
    OrGdTeRgComponent,
    DeZcPtPcComponent,
    DePsTePcComponent,
    DeIcImPcComponent,
    AsGdImImComponent,
    AsGdTeImComponent,
    AsGdTeTeComponent,
    AsGdComponent,
    DeComponent,
    EtGdImPtComponent,
    EtGdTePtComponent,
    CoLdLvIdComponent,
    ReCrNoPcComponent,
    ReCrTePcComponent,
    EtGdComponent
  ],
  entryComponents: [ModalMediaComponent, ModalViewerComponent],
  providers: [
    AuthGuard,
    QuizService,
    QuestionService,
    ConfigService,
    ScreenService,
    PlatformWrapperService,
    UtilsService,
    DragulaService
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/icons/mdi.svg')); // Or whatever path you placed mdi.svg at
  }
}
