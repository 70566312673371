import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {UtilsService} from '../../../services/service.utils';


@Component({
  selector: 'app-co-ld-lv-id',
  templateUrl: './co-ld-lv-id.component.html'
})
export class CoLdLvIdComponent implements OnInit, OnChanges {
  sReponseRetour: any = '';
  aPropositions: any = [];
  aRetour: any = [];
  aSolution: any = [];
  aPropositionsInit: any = [];
  indexInit: number;
  // recupere le quiz
  @Input() quiz: any;
  @Input() config: any;
  @Input() currentIndex: number;
  // envoi au parent la methode
  @Output()
  updStateProposition: EventEmitter<string> = new EventEmitter();
  @Output()
  showMediaPreview: EventEmitter<string> = new EventEmitter();

  constructor(private utilsService: UtilsService) {
  }

  ngOnInit() {
    this.indexInit = this.quiz.questions[this.quiz.index].id;
    this.aPropositionsInit = (Array.isArray(this.quiz.questions[this.quiz.index].propositions)) ? this.quiz.questions[this.quiz.index].propositions : [this.quiz.questions[this.quiz.index].propositions];
    this.aRetour = [];
    this.aSolution = [];
    this.aPropositions = [];
    // initialise les combos depuis le xml
    this.initCombo();
    // si reponse
    if (this.config.corrections) {
      // affiche la solution non la correction
      if (this.quiz.questions[this.quiz.index].solutions) {
        for (const solution in this.quiz.questions[this.quiz.index].solutions) {
          if (solution) {
            for (const prop in this.aPropositionsInit) {
              if (prop) {
                this.quiz.questions[this.quiz.index].solutions = (typeof this.quiz.questions[this.quiz.index].solutions.length === 'undefined') ? [this.quiz.questions[this.quiz.index].solutions] : this.quiz.questions[this.quiz.index].solutions;
                if (this.quiz.questions[this.quiz.index].solutions &&
                  this.quiz.questions[this.quiz.index].solutions[solution] &&
                  this.aPropositionsInit[prop].ordre === this.quiz.questions[this.quiz.index].solutions[solution].ordre_proposition) {
                  this.aPropositionsInit[prop].text = this.quiz.questions[this.quiz.index].solutions[solution].CDATA;
                  for (const prop in this.aPropositionsInit) {
                    if (this.aPropositionsInit[prop].ordre === this.quiz.questions[this.quiz.index].solutions[solution].ordre_proposition) {
                      const aProposition = this.aPropositionsInit[prop].CDATA.split('##');
                      if (aProposition) {
                        let valueProp = 0;
                        for (const propal in aProposition) {
                          if (aProposition[propal].trim() === this.aPropositionsInit[prop].text.trim()) {
                            this.aPropositionsInit[prop].value = valueProp.toString();
                          }
                          valueProp++;
                        }
                      }

                    }
                  }
                  this.aPropositionsInit[prop].checked = true;
                }
              }
            }
          }
        }
      }
    } else {
      if (typeof this.quiz.questions[this.quiz.index].answers !== 'undefined' && this.quiz.questions[this.quiz.index].answers.length > 0) {
        if (this.quiz.questions[this.quiz.index].answers) {
          for (const answer in this.quiz.questions[this.quiz.index].answers) {
            if (answer) {
              for (const prop in this.aPropositionsInit) {
                if (this.aPropositionsInit[prop].ordre === this.quiz.questions[this.quiz.index].answers[answer].order_proposition) {
                  this.aPropositionsInit[prop].text = this.quiz.questions[this.quiz.index].answers[answer].value;
                  this.aPropositionsInit[prop].value = this.quiz.questions[this.quiz.index].answers[answer].value_option;
                  this.aPropositionsInit[prop].checked = true;
                }
              }
            }
          }
        }
      }
    }

    // construit la question en html
    this.initQuestion();
    // associe les combo a levenement onchange pour mettre a jour la reponse et le btn validate
    // setTimeout(() => {this.initOnChangeCombo()},300);

    this.updateNextButton();
  }

  // hack ngOnChanges ne bind pas le changement sur le quiz quiz.index ok
  // qd 2 zc se suive le composant n est pas detruit et on ne repasse pas dans le ngonint
  // ngDoCheck(): void {
  //   if (this.quiz.questions[this.quiz.index].id != this.indexInit) {
  //     this.indexInit = this.quiz.questions[this.quiz.index].id;
  //     this.ngOnInit();
  //   }
  // }

  ngOnChanges(changes: SimpleChanges) {
    if (this.currentIndex !== this.indexInit) {
      this.indexInit = this.currentIndex;
      this.ngOnInit();
    }
  }

  // mode objet
  initCombo() {
    // construction des combos
    let indexVal = 0;
    for (const prop of this.aPropositionsInit) {
      const proposition: any = {};
      proposition.id = prop.ordre;
      const oSelect: any = {};
      oSelect.id = 'SelectLD' + this.quiz.questions[this.quiz.index].id + '_' + prop.ordre;
      // ordre
      oSelect.name = prop.ordre;
      oSelect.value = indexVal;
      indexVal++;
      let option: any = {};
      // separateur proposition ##
      let propLibelle = prop.CDATA.split('##');
      const initProposition = [];
      for (const i in propLibelle) {
        if (propLibelle[i]) {
          initProposition.push({
            label: propLibelle[i],
            id: i
          });
        }
      }
      const aOption = [];
      let flagAlphaNum = false;
      if (typeof this.quiz.questions[this.quiz.index].proposition_alpha_num !== 'undefined' &&
        this.quiz.questions[this.quiz.index].proposition_alpha_num === '1'
      ) {
        flagAlphaNum = true;
      }
      if (flagAlphaNum) {
        propLibelle = propLibelle.sort();
      }
      for (const i in propLibelle) {
        if (propLibelle[i]) {
          option = {};
          // let option = document.createElement("md-option") as HTMLOptionElement;
          option.text = propLibelle[i];
          for (const j in initProposition) {
            if (initProposition[j].label === propLibelle[i]) {
              option.value = initProposition[j].id;
              break;
            }
          }
          if (option.text !== '') {
            aOption.push(option);
          }
        }
      }
      oSelect.options = aOption;
      proposition.oSelect = oSelect;
      this.aPropositions.push(proposition);
    }
  }

  // mise a jour des propositions
  updStatePropositionCoLD(evt, idProp, idSelect) {
    for (const prop of this.aPropositionsInit) {
      if (prop.ordre === idProp) {
        prop.text = prop.CDATA.split('##')[evt.value];
        // id de l'option
        prop.value = evt.value;
        this.updStateProposition.emit(prop);
        // ajustement de la taille de la combo au contenu
        this.adjustComboSize(prop, idSelect);
      }
    }
    this.updateNextButton();
  }

  // retaille les combo en fonction de leur contenu
  adjustComboSize(prop, idSelect) {
    const numUpper = prop.text.length - prop.text.replace(/[A-Z]/g, '').length;
    const ratioMinuscule = prop.text.replace(/[A-Z]/g, '').length < 30 ? 9 : 8;
    let width = numUpper * 13 + prop.text.replace(/[A-Z]/g, '').length * ratioMinuscule;
    if (this.config.browser === 'ios') {
      width = width * 1.5;
    }
    width = width < 30 ? 50 : width;
    document.getElementById(idSelect).style.width = width + 'px';
  }

  initQuestion() {
    let idSelect: number;
    let sReponse: any = this.quiz.questions[this.quiz.index].cibles[0].CDATA;

    while (sReponse.length > 0) {
      if (sReponse.indexOf('{{') >= 0) {
        this.sReponseRetour += sReponse.substring(0, sReponse.indexOf('{{'));
        // au premier passage
        let oCombo: any = {};
        if (this.aRetour.length === 0) {
          oCombo.type = 'string';
          oCombo.content = sReponse.substring(0, sReponse.indexOf('{{'));
          this.aRetour.push(oCombo);
          this.aSolution.push(oCombo);
        }
        idSelect = sReponse.substring(sReponse.indexOf('{{') + 2, sReponse.indexOf('}}'));
        // parcour aProposition pour trouver aProposition[0].id correspondant
        let oSelectProp;
        let oSelectValue = '';
        for (const prop of this.aPropositions) {
          if (idSelect === prop.id) {
            oSelectProp = prop;
            break;
          }
        }
        for (const oSelected of this.aPropositionsInit) {
          if (oSelectProp.id === oSelected.ordre && typeof oSelected.text !== 'undefined') {
            // ajustement de la taille de la combo au contenu
            const sSelect = 'SelectLD' + this.quiz.questions[this.quiz.index].id + '_' + oSelected.ordre;
            // this.adjustComboSize(oSelected,idSelect);
            setTimeout(() => {
              this.adjustComboSize(oSelected, sSelect);
            }, 400);
            oSelectValue = oSelected.value;
          }
        }
        oCombo = {};
        oCombo.type = 'combo';
        oCombo.content = oSelectProp;
        oCombo.value = oSelectValue;
        this.aRetour.push(oCombo);
        for (const solution of this.quiz.questions[this.quiz.index].solutions) {
          if (oCombo.content.id === solution.ordre_proposition) {
            oCombo.content.oSelect.selected = solution.CDATA;
          }
        }
        this.aSolution.push(oCombo);
        this.sReponseRetour += '<span class="lvanswerparent"><span id="correctSelect' + this.quiz.questions[this.quiz.index].id + '_' + idSelect + '" class="lvanswer"></span> ' + oSelectProp.outerHTML + ' </span>';
        sReponse = sReponse.substring(sReponse.indexOf('}}') + 2, sReponse.length);

        const sReponseSuite = '';
        // cas reste des combos
        if (sReponse.indexOf('{{') >= 0) {
          oCombo = {};
          oCombo.type = 'string';
          oCombo.content = sReponse.substring(0, sReponse.indexOf('{{'));
          this.aRetour.push(oCombo);
          this.aSolution.push(oCombo);
        }
      } else {
        this.sReponseRetour += ' ' + sReponse;
        const oCombo: any = {};
        oCombo.type = 'string';
        oCombo.content = sReponse;
        this.aRetour.push(oCombo);
        this.aSolution.push(oCombo);
        sReponse = '';
      }
    }
    // console.log(this.aSolution);
  }

  // affiche la modal
  showMedia($event, filename) {
    this.showMediaPreview.emit(filename);
  }

  // recupere le src des images
  setImageSrc(type, data) {
    return this.utilsService.setImgSrc(type, data, this.config, this.quiz);
  }

  updateNextButton() {
    const propositions = (Array.isArray(this.quiz.questions[this.quiz.index].propositions)) ? this.quiz.questions[this.quiz.index].propositions : [this.quiz.questions[this.quiz.index].propositions];
    // CDATA: "matin##soir##", ordre: "1", text: "matin", value: "0", checked: true
    let flag = false;
    console.log('====>');
    console.log(propositions);
    for (const prop of propositions) {
      // flag = true;
      if (typeof prop['checked'] === 'undefined' || !prop['checked']) {
        prop['checked'] = false;
        prop['text'] = 'NULL';
        prop['value'] = 'NULL';
        // flag = false;
        // break;
      }
      if (prop['checked']){
        flag = true;
      }
    }
    this.quiz.next = flag;
  }
}
