import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Quiz} from '../models/quiz';
import {ConfigService} from './service.config';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class QuestionService {

  public quiz;
  private _questionSource = new BehaviorSubject<Quiz>(null);
  question$ = this._questionSource.asObservable();

  constructor(private http: HttpClient,
              private configService: ConfigService) {
  }

  get(question): Promise<any> {
    // console.log('QuestionsService.get -> question: ' + this.configService.config.apiUrl + ' ' + JSON.stringify(question));
    return this.http.post(this.configService.config.apiUrl + 'api/question/get',
      {
        langue: this.configService.config.langue,
        path_media: this.configService.config.pathMedia,
        question: question,
        repository_url: this.configService.config.ocqUrl,
        territory: this.configService.config.territoire,
      })
      .toPromise()
      .then(response => {
        // this.quiz = response.json();
        this.quiz = response;
        this._questionSource.next(this.quiz);
      })
      .catch(this.handleError);
  }


  set(quiz) {
    this.quiz = quiz;
  }

  getCurrentQuiz() {
    // console.log(this.quiz);
    return this.quiz;
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

}

