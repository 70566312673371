import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ConfigService} from '../services/service.config';
import {QuestionService} from './service.questions';

@Injectable()
export class PlatformWrapperService {

  private _quizSource = new BehaviorSubject<any>(null);
  quiz$ = this._quizSource.asObservable();
  private _questionSource = new BehaviorSubject<any>(null);
  question$ = this._questionSource.asObservable();

  onMessage: any = (event: any) => {
    // erreur compilation if (event.data && (typeof event.data === "array" || typeof event.data === 'object')) {
    if (event.data && (typeof event.data === 'object')) {
      const method = event.data[0];
      const datas: any[] = event.data[1];

      // console.log('window.onMessage -- method: ' + method + ' - datas: ' + JSON.stringify(datas));
      switch (method) {
        case 'config':
          // On reçoit la config d'eProfil
          datas['mode'] = 'EPROFIL';
          this.configService.set(datas);
          // On demande à eProfil une question
          window.parent.postMessage(['question'], '*');
          break;
        case 'question':
          // eProfil nous renvoi une question, on la charge
          this._questionSource.next(datas);
          break;
        case 'save':
          // TODO envoyer question transformee
          const question = this.questionService.getCurrentQuiz();
          window.parent.postMessage(['question-save', question], '*');
          break;
        default:
          break;
      }
    }
  }

  constructor(public configService: ConfigService,
              public questionService: QuestionService) {
    // intercepte les onmessage
    if (window.addEventListener) {
      window.addEventListener('message', this.onMessage.bind(this), false);
    } else {
      (<any>window).attachEvent('onmessage', this.onMessage.bind(this)); // ie8
    }
  }

  wrapperInit() {
    // window.parent.config génére une erreur bloquante: Uncaught DOMException: Blocked a frame with origin "http://127.0.0.1:4700" from accessing a cross-origin frame.
    let windowParentCONFIG;
    try {
      windowParentCONFIG = window.parent['CONFIG'];
    } catch (error) {
      windowParentCONFIG = undefined;
    }

    // MODE PREVISU OCQ
    // if (typeof CONFIG !== 'undefined') {
    // }
    if (typeof windowParentCONFIG !== 'undefined') {
      windowParentCONFIG['mode'] = 'EPROFIL';
      this.configService.set(windowParentCONFIG);
      window.parent.postMessage(['question'], '*');
      // this._questionSource.next();
    } else if (window.parent.length > 0) {
      // MODE EPROFIL
      window.parent.postMessage(['config'], '*');
    } else {
      // MODE 'AUTRE'
      this.configService.set({mode: 'QUIZ'});

      // TODO -> pour le moment on envoi les infos en dur,
      // TODO -> Microleanring 'pourrait' communiquer en MODE QUIZ avec le onMessage et nous renvoyé l'id et l'URL à utiliser pour charger le quiz
      this.configService.set({quizUrl: 'http://127.0.0.1:4710/datas/quiz/'});
      // this.configService.set({quizUrl: 'http://192.168.1.208:4710/datas/quiz/'});
      // this.configService.set({quizUrl: 'http://10.5.0.129:4710/datas/quiz/'});
      const quiz = {id: 1};  // quiz
      // let quiz = { id: 9374 };
      // let quiz = { id: 11864 };  //
      // let quiz = { id: 11885 };
      // let quiz = { id: 9340 }; //layout 3 de ps
      // let quiz = { id: 11889 };
      // let quiz = { id: 9313 }; //question image zone a cliquer
      // let quiz = { id: 432 }; //ordonnancement
      // let quiz = { id: 7210 }; //asgdTEIM
      // let quiz = { id: 290 }; //asgdIMIM
      // let quiz = { id: 8839 }; //asgdIMTE
      // let quiz = { id: 4152 }; //asgdTETE
      // let quiz = { id: 4099 }; //et gd im pt
      // let quiz = { id: 2334 }; //et gd im pt
      // let quiz = { id: 11849 };  //quiz
      // let quiz = { id: 777 };  //no exist
      // let quiz = { id: 11824 };
      // let quiz = { id: 10069 };  //CO_LD_LV_ID_U
      // let quiz = { id: 5173 };  //CO_LD_LV_ID_U
      // let quiz = { id: 13058 };  //formatC
      // let quiz = { id: 13060 };  //formatC
      this._quizSource.next(quiz);
    }
  }

}
