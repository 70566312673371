import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DeComponent} from '../de/de.component';

@Component({
  selector: 'app-de-ps-te-pc',
  templateUrl: './de-ps-te-pc.component.html'
})
export class DePsTePcComponent extends DeComponent implements OnInit {
  // recupere le quiz
  @Input() quiz: any;
  @Input() config: any;
  @Input() configSwiper: any;

  // envoi au parent la methode
  @Output()
  updStateProposition: EventEmitter<string> = new EventEmitter();
  @Output()
  showMediaPreview: EventEmitter<string> = new EventEmitter();

  ngOnInit() {

    if (this.config.corrections) {
      // affiche la solution non la correction
      for (const solution in this.quiz.questions[this.quiz.index].solutions) {
        if (solution) {
          const propositions = (Array.isArray(this.quiz.questions[this.quiz.index].propositions)) ? this.quiz.questions[this.quiz.index].propositions : [this.quiz.questions[this.quiz.index].propositions];
          for (const prop of propositions) {
            if (prop.ordre === this.quiz.questions[this.quiz.index].solutions[solution].ordre_proposition && this.quiz.questions[this.quiz.index].solutions[solution].status.toString() === '1') {
              prop.checked = true;
              break;
            }
          }
        }
      }
    } else {
      // si random et aucune reponse saisie
      let flagRandom = false;
      if (typeof this.quiz.questions[this.quiz.index].answers !== 'undefined') {
        const answers = (Array.isArray(this.quiz.questions[this.quiz.index].answers)) ? this.quiz.questions[this.quiz.index].answers : [this.quiz.questions[this.quiz.index].answers];
        for (const answer of answers) {
          const propositions = (Array.isArray(this.quiz.questions[this.quiz.index].propositions)) ? this.quiz.questions[this.quiz.index].propositions : [this.quiz.questions[this.quiz.index].propositions];
          for (const prop of propositions) {
            if (prop.ordre === answer.order_proposition && answer.status.toString() === '1') {
              prop.checked = true;
              break;
            }
          }
        }
      }

      if (typeof this.quiz.questions[this.quiz.index].proposition_random !== 'undefined' &&
        this.quiz.questions[this.quiz.index].proposition_random === '1'
      ) {
        for (const prop of this.quiz.questions[this.quiz.index].propositions) {
          if (prop['checked']) {
            flagRandom = true;
            break;
          }
        }
        if (!flagRandom) {
          this.utilsService.getShuffleProposition(this.quiz.questions[this.quiz.index].propositions);
        }
      }

      // si alphaNum et aucune reponse saisie
      let flagAlphaNum = false;
      if (typeof this.quiz.questions[this.quiz.index].proposition_alpha_num !== 'undefined' &&
        this.quiz.questions[this.quiz.index].proposition_alpha_num === '1'
      ) {
        for (const prop of this.quiz.questions[this.quiz.index].propositions) {
          if (prop['checked']) {
            flagAlphaNum = true;
            break;
          }
        }
        if (!flagAlphaNum) {
          this.utilsService.getSortAlphaNum(this.quiz.questions[this.quiz.index].propositions);
        }
      }
    }
    super.updateNextButton();
  }

  // mise a jour des propositions
  updStateProp(proposition) {
    this.updStateProposition.emit(proposition);
    super.updateNextButton();
  }

  // recupere le src des images
  setImageSrc(type, data) {
    return this.utilsService.setImgSrc(type, data, this.config, this.quiz);
  }

  // affiche la modal
  showMedia($event, filename) {
    this.showMediaPreview.emit(filename);
  }

}
