import {Injectable} from '@angular/core';

@Injectable()
export class UtilsService {

  constructor() {
  }

  public getFolderTree(id, length = 8) {
    let subDir = '';
    let str = id.toString();
    while (str.length < length) {
      str = '0' + str;
    }
    const arrTmp = str.match(/.{1,2}/g);
    for (let i = 0; i < arrTmp.length; i++) {
      subDir += arrTmp[i] + '/';
    }
    return subDir;
  }

  // -------------- //
  // IMAGE SRC ATTR //
  // -------------- //
  setImgSrc(type, data, config, quiz) {
    if (type === 'support') {
      data.filename = data.supFilename;
    }
    // QUIZ
    if (config.mode === 'QUIZ') {
      const filename = (type === 'template') ? this.getImgSrc(data, 'png') : data.filename;
      return config.quizUrl + quiz.folder + filename;
    } else if (config.mode === 'EPROFIL') {
      return quiz.questionUrl + quiz.folder + (data.filename || data.src);
    }
    // else { //TODO renvoyée une image type lien cassée }
    return '';
  }

  public getImgSrc(value, extension): string {
    let filename = value.filename;
    filename = filename.split('.');
    filename[filename.length - 2] += '_thumb';
    if (typeof extension !== 'undefined') {
      filename[filename.length - 1] = extension;
      // les miniatures sont en png dans mirolearning... ce parametre supplémentaire permet de rester générique
    }
    return filename.join('.');
  }

  // ------------------------------------------------------ //
  // Ordrea aleatoire different de l'entree                 //
  // entry :                                                //
  // notEqual : ordre obligatoirement different de l'entree //
  // ------------------------------------------------------ //
  getShuffleProposition(entry, notEqual = false) {
    const initProp = Object.assign({}, entry);
    let nbProp = 0;
    // nombre de proposition
    for (const prop in initProp) {
      if (initProp.hasOwnProperty(prop)) {
        nbProp++;
      }
    }
    let currentIndex: number = nbProp;
    let temporaryValue: any;
    let randomIndex = 0;
    let shuffle = false;
    while (!shuffle) {
      while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        temporaryValue = entry[currentIndex];
        entry[currentIndex] = entry[randomIndex];
        entry[randomIndex] = temporaryValue;
      }
      // teste si different du tableau initial
      if (notEqual) {
        for (const prop in initProp) {
          if (initProp.hasOwnProperty(prop) && initProp[prop].ordre !== entry[prop].ordre) {
            shuffle = true;
            break;
          }
        }
      } else {
        shuffle = true;
      }
    }
    return entry;
  }

  // ------------------------------------------------------ //
  // Ordre sortie de maniere alpha numerique                //
  // entry :                                                //
  // ------------------------------------------------------ //
  getSortAlphaNum(entry) {
    entry = entry.sort(function (a, b) {
      return (a.CDATA > b.CDATA);
    });
    return entry;
  }

}
