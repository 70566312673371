export class CalculatorService {

  private stuff = [];
  private write = '';

  constructor() {
  }

  stuffToWrite() {
    this.write = '';
    this.stuff.forEach((k: any) => {
      this.write += k.toString();
    });

  }

  isOperator(val) {
    return isNaN(val);
  }

  countDecimals(val) {
    if (Math.floor(val) === val) return 0;
    return val.toString().split('.')[1].length || 0;
  }

  input(n) {
    n = n.toString();
    if (n === '.') {
      if (!this.stuff.length) {
        return this.write;
      }
      let last = this.stuff.pop();
      if (this.isOperator(last) || Math.ceil(last) !== parseInt(last)) {
        this.stuff.push(last);
      } else {
        last += '.';
        this.stuff.push(last);
        this.stuffToWrite();
      }
      return this.write;
    }

    if (this.isOperator(n)) {
      if (!this.stuff.length) {
        return this.write;
      }
      const last = this.stuff.pop();
      if (!this.isOperator(last)) {
        this.stuff.push(last);
      }
      this.stuff.push(n);
    } else if (this.stuff.length) {
      let last = this.stuff.pop();
      if (this.isOperator(last)) {
        this.stuff.push(last);
        this.stuff.push(n);
      } else if (last.toString() === '0') {
        this.stuff.push(n);
      } else {
        last += n.toString();
        this.stuff.push(last);
      }
    } else {
      this.stuff.push(n);
    }
    this.stuffToWrite();
    return this.write;
  }

  calculate() {
    if (!this.stuff.length) {
      return '';
    }
    const last = this.stuff.pop();
    if (this.isOperator(last)) {
      this.stuff.push(last);
      return '';
    }
    this.stuff.push(last);
    eval('res = ' + this.write + ';');
    let res = eval(this.write);
    if (res % 1 !== 0) {
      // res = Math.round(res * 100) / 100;
      // res = res.toFixed(3);
      const cptDecimals = this.countDecimals(res);
      res = res.toFixed((cptDecimals > 3) ? 3 : cptDecimals);
    }
    return res;
  }

  inverse() {
    if (this.stuff.length > 0) {
      const last = this.stuff.pop();
      if (!this.isOperator(last)) {
        this.stuff.push(last * -1);
      } else {
        this.stuff.push(last);
      }
    }
    this.stuffToWrite();
    return this.write;
  }

  reset() {
    this.stuff = [];
    this.write = '';
    return;
  }

  cancel() {
    this.stuff.pop();
    this.stuffToWrite();
    return this.write;
  }

}
