import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Quiz} from '../../models/quiz';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {ModalViewerComponent} from '../modal-viewer/modal-viewer.component';

@Component({

  selector: 'app-button-help',
  templateUrl: 'button-help.component.html',
})
export class ButtonHelpComponent {

  // recupere le quiz
  @Input() quiz: Quiz;

  // envoi au parent la methode
  @Output()
  help: EventEmitter<string> = new EventEmitter();

  constructor(public dialog: MatDialog) {
  }

  helpQuestion() {
    // this.help.emit();
    const config: MatDialogConfig = new MatDialogConfig;
    config.height = '300px';
    config.width = '600px';
    config.data = {
      message: '<br><span>' + this.quiz.questions[this.quiz.index].help + '</span>',
      title: '<div class="modal-title">Aide question  ' + this.quiz.questions[this.quiz.index].id + ' </div>'
    };
    this.dialog.open(ModalViewerComponent, config);
  }
}
