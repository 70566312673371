import {
  Component, OnInit, Input, HostListener, Output, EventEmitter, OnChanges, SimpleChanges
} from '@angular/core';
import {Config} from '../../../models/config';
import {UtilsService} from '../../../services/service.utils';

@Component({
  selector: 'app-de-zc-pt-pc',
  templateUrl: './de-zc-pt-pc.component.html',
  styleUrls: ['./de-zc-pt-pc.component.css']
})
export class DeZcPtPcComponent implements OnInit, OnChanges {

  // recupere le quiz
  @Input() quiz: any;
  @Input() config: Config;
  @Input() currentIndex: number;

  // envoi au parent la methode
  @Output()
  updStateProposition: EventEmitter<string> = new EventEmitter();

  svg: any = {scale: 1};
  ripple: any = {};
  showImg = false;
  indexInit: number;

  constructor(private utilsService: UtilsService) {
  }

  ngOnInit() {
    this.indexInit = this.quiz.questions[this.quiz.index].id;
    this.setMedia();

    if (this.config.corrections) {
      // affiche la solution non la correction
      for (const solution in this.quiz.questions[this.quiz.index].solutions) {
        if (solution) {
          const propositions = (Array.isArray(this.quiz.questions[this.quiz.index].propositions)) ? this.quiz.questions[this.quiz.index].propositions : [this.quiz.questions[this.quiz.index].propositions];
          for (const prop of propositions) {
            if (prop.ordre === this.quiz.questions[this.quiz.index].solutions[solution].ordre_proposition && this.quiz.questions[this.quiz.index].solutions[solution].status.toString() === '1') {
              prop.checked = true;
              break;
            }
          }
        }
      }
    } else {
      // si reponse
      if (typeof this.quiz.questions[this.quiz.index].answers !== 'undefined') {
        const answers = (Array.isArray(this.quiz.questions[this.quiz.index].answers)) ? this.quiz.questions[this.quiz.index].answers : [this.quiz.questions[this.quiz.index].answers];
        for (const answer of answers) {
          const propositions = (Array.isArray(this.quiz.questions[this.quiz.index].propositions)) ? this.quiz.questions[this.quiz.index].propositions : [this.quiz.questions[this.quiz.index].propositions];
          for (const prop of propositions) {
            if (prop.ordre === answer.order_proposition && answer.status.toString() === '1') {
              prop.checked = true;
              break;
            }
          }
        }
      }
    }
    // hack pour ios le load ou onload ne fonctionne pas
    if (this.config.browser === 'ios') {
      const instance = this;
      setTimeout(function () {
        instance.onLoadMedia();
      }, 1000);
    }
    this.updateNextButton();

  }

  // hack ngOnChanges ne bind pas le changement
  // qd 2 zc se suive le composant n est pas detruit et on ne repasse pas dans le ngonint
  // ngDoCheck(): void {
  //   if(this.quiz.questions[this.quiz.index].id != this.indexInit) {
  //     this.indexInit = this.quiz.questions[this.quiz.index].id;
  //     this.setMedia();
  //   }
  // }

  ngOnChanges(changes: SimpleChanges) {
    if (this.currentIndex !== this.indexInit) {
      this.indexInit = this.currentIndex;
      this.setMedia();
      this.updateNextButton();
    }
  }

  // ------ //
  // MEDIAS //
  // ------ //
  setMedia() {
    this.showImg = false;
    if (this.quiz.questions[this.quiz.index].type === 'DE_ZC_PT_PC_U' || this.quiz.questions[this.quiz.index].type === 'DE_ZC_PT_PC_M') {
      this.svg.src = this.utilsService.setImgSrc('support', this.quiz.questions[this.quiz.index].medias[0], this.config, this.quiz);
    }

    // cas particulier ie
    if (/Trident/.test(navigator.userAgent) || /Edge/.test(navigator.userAgent)) {
      setTimeout(() => {
        this.onLoadMedia();
      }, 300);
    }
  }

  // au chargement du media on recupere la taille de l'image
  onLoadMedia() {
    const newImg = new Image();
    // this.container = this.img.parentElement;
    const oSvg = this.svg;
    newImg.onload = function () {
      oSvg.width_init = newImg.width;
      oSvg.width = newImg.width;
      oSvg.height_init = newImg.height;
      oSvg.height = oSvg.height_init;
      oSvg.propositionDisplay = false;
    };

    newImg.src = this.svg.src;
    // cas image plus grande que la zone
    const scaleImgSup = 0;
    setTimeout(() => {
      this.svgScale();
      this.svg.propositionDisplay = true;
    }, 100);

    setTimeout(() => {
      // cas particulier ie
      if (this.config.browser === 'ieBrowser') {
        this.setIeChecked();
      }
      this.showImg = true;
    }, 200);
  }

  // on calcule le scale pour bien positionner les propositions
  svgScale() {
    const parentWidth = document.getElementById('DE_ZC_PT_PC_U').parentElement.getBoundingClientRect().width;
    const parentHeight = document.getElementById('DE_ZC_PT_PC_U').parentElement.getBoundingClientRect().height;
    const imgMedia = document.getElementById('imgMedia').getBoundingClientRect();
    this.svg.scale = parentWidth < this.svg.width_init ? parentWidth / this.svg.width_init : 1;
    this.svg.scale = parentHeight < this.svg.height_init * this.svg.scale ? parentHeight / this.svg.height_init * this.svg.scale : this.svg.scale;
    this.svg.height = this.svg.height_init * this.svg.scale;
    this.svg.width = this.svg.width_init * this.svg.scale;
  }

  // --------------------------- //
  // BINDING ZONE - PROPOSITIONS //
  // --------------------------- //
  updStatePropositionDEZCPT(proposition, evt) {
    // TODO affichage correction
    let element = proposition.CDATA.split(',');
    this.updStateProposition.emit(proposition);
    const oSvg = document.getElementById(evt.target.id);
    const oRipple = document.getElementById('ripple-shape');

    const testRipple = document.getElementById('ripple-ellipse-shape');
    const x = evt.offsetX,
      y = evt.offsetY,
      w = evt.target.getBoundingClientRect().width,
      h = evt.target.getBoundingClientRect().height,
      scale_ratio = w;

    // if (typeof evt.target.cx === 'undefined') {
    /*
    this.ripple = {
      height: evt.target.getBoundingClientRect().height,
      width: evt.target.getBoundingClientRect().width,
      x: evt.target.x.baseVal.value,
      y: evt.target.y.baseVal.value
    }

    setTimeout(() => {
      TweenLite.fromTo(oRipple, 0.95, {
        x: 29,
        y: 29,
        transformOrigin: '50% 50%',
        scale: 0,
        opacity: 1
      }, {
        scale: scale_ratio,
        opacity: 0
      });
    }, 10);
    */
    // } else {
    // TODO pour ellipse ko
    // }
    setTimeout(() => {
      for (const prop of this.quiz.questions[this.quiz.index].propositions) {
        element = prop.CDATA.split(',');

        if (prop.checked) {
          // ko ie document.getElementById(element[1] + element[0]).classList.add("checked");
          if (document.getElementById(element[1] + element[0]).getAttribute('class').indexOf('checked') < 0) {
            document.getElementById(element[1] + element[0]).setAttribute('class', document.getElementById(element[1] + element[0]).getAttribute('class') + ' checked');
          }
        } else {
          let sClass = document.getElementById(element[1] + element[0]).getAttribute('class');
          if (document.getElementById(element[1] + element[0]).getAttribute('class').indexOf('checked') >= 0) {
            sClass = document.getElementById(element[1] + element[0]).getAttribute('class').substring(0, document.getElementById(element[1] + element[0]).getAttribute('class').indexOf('checked'));
          }
          // ko ie11 document.getElementById(element[1] + element[0]).classList.remove("checked");
          document.getElementById(element[1] + element[0]).setAttribute('class', sClass);
        }
      }
    }, 100);

    this.updateNextButton();
  }

  setIeChecked() {
    for (const prop of this.quiz.questions[this.quiz.index].propositions) {
      const element = prop.CDATA.split(',');
      if (prop.checked) {
        // ko ie document.getElementById(element[1] + element[0]).classList.add("checked");
        if (document.getElementById(element[1] + element[0]).getAttribute('class').indexOf('checked') < 0) {
          document.getElementById(element[1] + element[0]).setAttribute('class', document.getElementById(element[1] + element[0]).getAttribute('class') + ' checked');
        }
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.quiz.questions[this.quiz.index].type === 'DE_ZC_PT_PC_U' || this.quiz.questions[this.quiz.index].type === 'DE_ZC_PT_PC_M') {
      this.svgScale();
    }
  }

  updateNextButton() {
    const propositions = (Array.isArray(this.quiz.questions[this.quiz.index].propositions)) ? this.quiz.questions[this.quiz.index].propositions : [this.quiz.questions[this.quiz.index].propositions];
    let flag = false;
    for (const prop of propositions) {
      if (prop.checked) {
        flag = true;
        break;
      }
    }
    this.quiz.next = flag;
  }
}
