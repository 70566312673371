import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {DragulaService} from 'ng2-dragula';
import {Answer} from '../../../models/answer';
import {UtilsService} from '../../../services/service.utils';
import {Config} from '../../../models/config';
import {Subscription} from 'rxjs/internal/Subscription';


@Component({
  selector: 'app-or-gd-te-rg',
  templateUrl: './or-gd-te-rg.component.html'
})
export class OrGdTeRgComponent implements OnInit, OnDestroy, OnChanges {
  // dropSubscribe: any;
  dragSubscribe = new Subscription();

  @Output()
  showMediaPreview: EventEmitter<string> = new EventEmitter();

  // recupere le quiz
  @Input() quiz: any;
  @Input() config: Config;
  @Input() currentIndex: number;
  indexInit: number;

  constructor(private dragulaService: DragulaService,
              private utilsService: UtilsService) {


    // this.dragulaService.drag.unsubscribe();
    // BINDING DROP
    this.dragSubscribe.add(this.dragulaService.drop()
      .subscribe(({name, el, target, source, sibling}) => {
        this.setAnswer();
      }));

  }

  ngOnInit() {
    if (typeof this.quiz.questions[this.quiz.index].answers !== 'undefined' && this.quiz.questions[this.quiz.index].answers.length > 0) {
      // ordre proposition initial pour activer le btn next si retour ordre depart on ne peut pas valider le quiz
      // this.initProp = Object.assign({}, this.quiz.questions[this.quiz.index].propositions);
      // si des reponses on ordonne les propositions dans l'ordre des answers
      const oReoder: any = [];
      const newProp: any = [];
      for (const answer in this.quiz.questions[this.quiz.index].answers) {
        if (this.quiz.questions[this.quiz.index].answers[answer].status.toString() === '1') {
          newProp.push(this.quiz.questions[this.quiz.index].answers[answer]);
        }
      }
      for (const ans in newProp) {
        if (newProp[ans]) {
          for (const prop in this.quiz.questions[this.quiz.index].propositions) {
            if (this.quiz.questions[this.quiz.index].propositions[prop].ordre === newProp[ans].order_proposition) {
              oReoder.push(this.quiz.questions[this.quiz.index].propositions[prop]);
              break;
            }
          }
        }
      }
      this.quiz.questions[this.quiz.index].propositions = oReoder;
      this.updateNextButton();
    } else if (this.config.corrections) {
      const aReoder: any = [];
      if (this.quiz.questions[this.quiz.index].solutions) {
        for (const solution in this.quiz.questions[this.quiz.index].solutions) {
          if (this.quiz.questions[this.quiz.index].solutions[solution].status === '1') {
            for (const prop in this.quiz.questions[this.quiz.index].propositions) {
              if (this.quiz.questions[this.quiz.index].propositions[prop].ordre === this.quiz.questions[this.quiz.index].solutions[solution].ordre_proposition) {
                aReoder.push(this.quiz.questions[this.quiz.index].propositions[prop]);
                break;
              }
            }
          }
        }
      }
      this.quiz.questions[this.quiz.index].propositions = aReoder;
    } else {
      if (typeof this.quiz.questions[this.quiz.index].proposition_random !== 'undefined' &&
        (this.quiz.questions[this.quiz.index].proposition_random === '1' || this.quiz.questions[this.quiz.index].proposition_random === 'true')
      ) {
        this.utilsService.getShuffleProposition(this.quiz.questions[this.quiz.index].propositions, true);
      }
      if (typeof this.quiz.questions[this.quiz.index].proposition_alpha_num !== 'undefined' &&
        (this.quiz.questions[this.quiz.index].proposition_alpha_num === 'true' || this.quiz.questions[this.quiz.index].proposition_alpha_num === 'true')
      ) {
        this.utilsService.getSortAlphaNum(this.quiz.questions[this.quiz.index].propositions);
      }
      // ordre proposition initial pour activer le btn next si retour ordre depart on ne peut pas valider le quiz
      // this.initProp = Object.assign({}, this.quiz.questions[this.quiz.index].propositions);
    }

    // rg 3.2-001 le drag n'etant plus obligatoire il faut activer le btn et initialiser les reponses
    setTimeout(() => {
      this.updateNextButton();
      this.setAnswer();
    }, 300);
  }

  ngOnDestroy(): void {
    this.dragSubscribe.unsubscribe();
  }

  // hack ngOnChanges ne bind pas le changement
  // qd 2 or_gd se suive le composant n est pas detruit et on ne repasse pas dans le ngonint
  // ngDoCheck(): void {
  //   if (this.quiz.questions[this.quiz.index].id != this.indexInit) {
  //     this.indexInit = this.quiz.questions[this.quiz.index].id;
  //     this.ngOnInit();
  //   }
  // }

  ngOnChanges(changes: SimpleChanges) {
    if (this.currentIndex !== this.indexInit) {
      this.indexInit = this.currentIndex;
      this.ngOnInit();
    }
  }

  // si l'ordre est different de l'intial on peut valider
  updateNextButton() {
    /*
    Verifie si l'ordre n'est pas le meme que celui initié deprecated rg3.2-001 -002
    let flag = false;
    let i: number = 0;
    let newProp: any = [];
    for (let answer in this.quiz.questions[this.quiz.index].answers) {
      if(this.quiz.questions[this.quiz.index].answers[answer].status==1) {
        newProp.push(this.quiz.questions[this.quiz.index].answers[answer]);
      }
    }
    for (let prop in this.initProp) {
      if (parseInt(this.initProp[prop].ordre) !== parseInt(newProp[i].orderProposition)) {
        flag = true;
        break;
      }
      i++;
    }*/
    // let flag = true;
    this.quiz.next = true;
  }

  setAnswer() {
    const oSortable = document.getElementById('sortableRg_' + this.quiz.questions[this.quiz.index].id).children;
    // Si le quiz n'est pas à l'état FINI et que nous ne sommes PAS en mode CORRECTION
    // if (!$scope.quiz.correction.started && !$scope.quiz.finished) {
    let error = false;
    let answered = false;

    this.quiz.questions[this.quiz.index].answers = [];
    // Mise à jour du 'status' si question répondu dans la liste des questions
    let orderCible = 0;
    for (const prop in oSortable) {
      if (typeof oSortable[prop] === 'object') {
        const propOrdre = parseInt(oSortable[prop].getAttribute('prop_order'));
        let propChecked = 1;
        for (const sol of this.quiz.questions[this.quiz.index].solutions) {
          if (orderCible.toString() === sol.ordre_cible) {
            propChecked = (propOrdre.toString() === sol.ordre_proposition && orderCible.toString() === sol.ordre_cible) ? 1 : 0;
            if (propChecked.toString() !== sol.status) {
              error = true;
            }
            const answer: Answer = new Answer();
            answer.order_proposition = sol.ordre_proposition;
            answer.order_cible = sol.ordre_cible;
            answer.status = propChecked;
            this.quiz.questions[this.quiz.index].answers.push(answer);
          }
        }
        // On flag si réponse donnée par l'utilisateur (possibilité de faire précédent sans répondre en mode autonome
        if (propChecked) {
          answered = true;
        }
        orderCible++;
      }
    }
    this.updateNextButton();
    if (!answered) {
      this.quiz.questions[this.quiz.index].status = 2;
    } else {
      this.quiz.questions[this.quiz.index].status = error ? 0 : 1;
    }
  }

  // affiche la modal
  showMedia($event, filename) {
    this.showMediaPreview.emit(filename);
  }

  // recupere le src des images
  setImageSrc(type, data) {
    return this.utilsService.setImgSrc(type, data, this.config, this.quiz);
  }

}
