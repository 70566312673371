import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Quiz} from '../../models/quiz';
import {Subscription} from 'rxjs';
import {ScreenService} from '../../services/service.screenConfig';

@Component({
  selector: 'app-button-save',
  templateUrl: 'button-save.component.html',
})
export class ButtonSaveComponent implements OnInit {

  screenSubscription: Subscription;
  screenType: string;

  // recupere le quiz
  @Input() quiz: Quiz;
  @Input() config: any;

  // envoi au parent la methode
  @Output()
  save: EventEmitter<string> = new EventEmitter();

  constructor(private screenService: ScreenService) {
  }

  ngOnInit() {
    // BINDING widthscreen
    this.screenSubscription = this.screenService.screenType$.subscribe(screenType => {
      if (screenType) {
        this.screenType = screenType.toString();
      }
    });
  }

  saveQuestion() {
    this.save.emit();
  }
}
