import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {ConfigService} from './service.config';
import {BehaviorSubject} from 'rxjs';
import {Quiz} from '../models/quiz';

@Injectable()
export class QuizService {

  public quiz;
  private _quizSource = new BehaviorSubject<Quiz>(null);
  quiz$ = this._quizSource.asObservable();

  constructor(private http: HttpClient,
              public configService: ConfigService) {
  }

  get(quiz): Promise<any> {
    return this.http.post(this.configService.config.apiUrl + 'api/quiz/get', {
      quiz: quiz,
      quizUrl: this.configService.config.quizUrl,
      langue: this.configService.config.langue,
      territory: this.configService.config.territory
      // questionUrl: this.configService.config.questionUrl
      // pathMedias: this.configService.config.pathMedias
    })
      .toPromise()
      .then(response => {
        this.quiz = response;
        this._quizSource.next(this.quiz);
      })
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

}

