import {Component, HostListener, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {QuizService} from '../services/service.quiz';
import {ConfigService} from '../services/service.config';
import {UtilsService} from '../services/service.utils';
import {Config} from '../models/config';
import {Answer} from '../models/answer';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {SwiperComponent, SwiperConfigInterface} from 'ngx-swiper-wrapper';
import {QuestionService} from '../services/service.questions';
import {ModalViewerComponent} from '../components/modal-viewer/modal-viewer.component';
// import {setTimeout} from 'timers';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-question-content',
  templateUrl: 'navigator.component.html',
  animations: [
    trigger('animate', [
      transition(':enter', [
        style({transform: 'translateX(-100%)'}),
        animate('0.5s ease-in-out', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        style({transform: 'translateX(0%)'}),
        animate('0.5s ease-out', style({transform: 'translateX(-100%)'}))
      ])
    ])
  ]
})
export class NavigatorContentComponent implements OnInit, OnDestroy {

  config: Config;
  configSubscription: Subscription;
  quizSubscription: Subscription;
  questionSubscription: Subscription;
  quiz: any = {};
  calculatorState = false;
  isFullscreen = false;
  // calculatorClass: string = 'bounceOutLeft';
  calculatorClass = 0;
  showMedia = false;
  configSwiper: SwiperConfigInterface;
  imgSrc = '';
  lastIndex = 0;
  timerModal = 0;
  timer_modal = undefined;
  modal_open = false;
  keys = [];
  konami = '57,56,55,54,53,52,51,50,49'; // 987654321
  @ViewChild(SwiperComponent) swiperView: SwiperComponent;
  private ios: boolean;
  private ieBrowser: boolean;
  private chrome: boolean;
  private chromeMobile: boolean;
  private firefoxMobile: boolean;

  // color:string = ''; // Exemple pour la directive highlight
  private oldAndroid: boolean;

  constructor(private configService: ConfigService,
              private quizService: QuizService,
              private questionService: QuestionService,
              private utilsService: UtilsService,
              public dialog: MatDialog,
              public zone: NgZone) {

    /*
    this.configSwiper = {
      scrollbar: null,
      direction: 'horizontal',
      slidesPerView: 1,
      scrollbarHide: false,
      keyboardControl: true,
      mousewheelControl: true,
      scrollbarDraggable: true,
      scrollbarSnapOnRelease: true,
      pagination: '.swiper-pagination',
      paginationClickable: true
    };*/
  }

  ngOnInit() {
    // BINDING CONFIG
    this.configSubscription = this.configService.config$.subscribe(config => {
      if (config) {
        this.config = config;
        if (this.ios) {
          this.config.browser = 'ios';
        } else if (this.chromeMobile) {
          this.config.browser = 'chromeMobile';
        } else if (this.firefoxMobile) {
          this.config.browser = 'fierfoxMobile';
        } else if (this.ieBrowser) {
          this.config.browser = 'ieBrowser';
        } else if (this.oldAndroid) {
          this.config.browser = 'oldAndroid';
        } else if (this.chrome) {
          this.config.browser = 'chrome';
        }
      }
    });

    // BINDING QUIZ
    this.quizSubscription = this.quizService.quiz$.subscribe(quiz => {
      if (quiz) {
        this.quiz = quiz;
        this.quiz.index = 0;
        this.quiz.correction = {
          started: false
        };
        document.getElementById('loader-container').style.display = 'none';
      }
    });

    // BINDING QUESTION
    this.questionSubscription = this.questionService.question$.subscribe(question => {
      if (question) {
        // console.log('1===>', question);
        this.quiz = question;
        this.quiz.index = 0;
        this.quiz.correction = {
          started: false
          // started: true
        };
        document.getElementById('loader-container').style.display = 'none';
      }
    });
    // doubleclickko
    this.ios = /iPad|iPhone|iPod/.test(navigator.userAgent);
    // this.chromeMobile = /Chrome/.test(navigator.userAgent) && /Android/.test(navigator.userAgent);

    // test version android<5 doubleclickko
    const ua = navigator.userAgent;
    if (ua.indexOf('Android') >= 0) {
      const androidversion = parseFloat(ua.slice(ua.indexOf('Android') + 8));
      this.oldAndroid = (androidversion < 5);
    }

    if (/Chrome/.test(navigator.userAgent) && /Android/.test(navigator.userAgent)) {
      let version = navigator.appVersion.substring(navigator.appVersion.indexOf('Chrome/') + 7, navigator.appVersion.length);
      version = version.substring(0, version.indexOf('.'));
      if (parseInt(version) > 55) {
        this.chromeMobile = true;
      }
    }
    this.chrome = /Chrome/.test(navigator.userAgent);
    this.firefoxMobile = /Firefox/.test(navigator.userAgent) && /Android/.test(navigator.userAgent);
    this.ieBrowser = /Trident/.test(navigator.userAgent);
    this.setRegex();
  }

  setRegex(): void {
    // console.log('setregex');
    // transforme le retour a la ligne par le code html
    if (typeof this.quiz.index !== 'undefined') {
      if (this.lastIndex !== this.quiz.index || this.lastIndex === 0) {
        const match = /\r|\n/.exec(this.quiz.questions[this.quiz.index].title);
        if (match) {
          this.quiz.questions[this.quiz.index].title = this.quiz.questions[this.quiz.index].title.replace(new RegExp('\r|\n', 'g'), '<br/>');
        }
      }
    }
  }

  // ngDoCheck(): void {
  // transforme le retour a la ligne par le code html
  // if(typeof this.quiz.index != "undefined") {
  //   if(this.lastIndex != this.quiz.index || this.lastIndex == 0) {
  //     let match = /\r|\n/.exec(this.quiz.questions[this.quiz.index].title);
  //     if (match) {
  //       this.quiz.questions[this.quiz.index].title = this.quiz.questions[this.quiz.index].title.replace(new RegExp('\r|\n', 'g'), '<br/>');
  //     }
  //   }
  // }
  // }

  ngOnDestroy() {
    if (typeof this.quizSubscription !== 'undefined') {
      this.quizSubscription.unsubscribe();
    }
    if (typeof this.questionSubscription !== 'undefined') {
      this.questionSubscription.unsubscribe();
    }
    if (typeof this.configSubscription !== 'undefined') {
      this.configSubscription.unsubscribe();
    }
  }

  // voir si en full screen ou non depuis button-fullscreen
  setFullScreen(isFullscreen) {
    this.isFullscreen = isFullscreen;
  }

  // --------------------------------------- //
  // BINDING CHECKBOXs/RADIOs - PROPOSITIONS //
  // --------------------------------------- //
  updStateProposition(proposition) {
    // SI je ne suis pas en mode CORRECTION
    if (!this.quiz.correction.available) {
      switch (this.quiz.questions[this.quiz.index].type) {
        case 'DE_PS_TE_PC_U':
        case 'DE_IC_IM_PC_U':
        case 'DE_ZC_PT_PC_U':
          if (typeof proposition.checked === 'undefined') {
            proposition.checked = false;
          }
          for (const prop of this.quiz.questions[this.quiz.index].propositions) {
            if (proposition === prop) {
              prop['checked'] = !proposition.checked;
            } else {
              prop['checked'] = false;
            }
          }
          break;
        case 'DE_PS_TE_PC_M':
        case 'DE_IC_IM_PC_M':
        case 'DE_ZC_PT_PC_M':
          proposition.checked = !proposition.checked;
          break;
        case 'CO_LD_LV_ID_U':
          const propositions = (Array.isArray(this.quiz.questions[this.quiz.index].propositions)) ? this.quiz.questions[this.quiz.index].propositions : [this.quiz.questions[this.quiz.index].propositions];
          for (const prop of propositions) {
            if (proposition === prop) {
              prop['checked'] = (proposition.text.trim() !== '');
              break;
            }
          }
          break;
        default:
      }
    }
    // this.updateNextButton();
  }

  // ------------------------------------- //
  // UPDATE BUTTONS NEXT / PREVIOUS STATUS //
  // ------------------------------------- //
  // PROPRE AUX COMPOSANTS
  // updateNextButton() {
  //   let propositions = (Array.isArray(this.quiz.questions[this.quiz.index].propositions)) ? this.quiz.questions[this.quiz.index].propositions : [this.quiz.questions[this.quiz.index].propositions];
  //   let flag = false;
  //   for (let prop of propositions) {
  //     if (this.quiz.questions[this.quiz.index].type == "CO_LD_LV_ID_U"){
  //       flag=true;
  //       if (typeof prop['checked'] === 'undefined' || !prop['checked']) {
  //         flag = false;
  //         break;
  //       }
  //     }else {
  //       if (prop['checked']) {
  //         flag = true;
  //         break;
  //       }
  //     }
  //   }
  //   this.quiz.next = flag;
  // };

  // ----------------- //
  // PREVIOUS QUESTION //
  // ----------------- //
  previous() {
    this.setAnswer();

    if (this.config.mode.toUpperCase() === 'EPROFIL') {
      const datas = {
        idQuestion: parseInt(this.quiz.questions[this.quiz.index].id),
        responses: this.transformAnswersEprofil(false),
        dontknow: false,
        previous: true
      };
      window.parent.postMessage(['setResponse', datas], '*');
    } else {
      this.quiz.index--;
    }
    this.setRegex();
    // this.updateNextButton();
  }

  // ------------- //
  // NEXT QUESTION //
  // ------------- //
  next() {
    // setTimerQuestion('stop'); //TODO gestion du timer
    this.setAnswer();
    this.quiz.index++;
    this.setRegex();
    // this.updateNextButton();
    // setTimerQuestion('start');//TODO gestion du timer
  }

  // ---------------------------------- //
  // DENIERE QUESTION - SAUVEGARDE QUIZ //
  // ---------------------------------- //
  save() {
    // setTimerQuestion('stop'); //TODO gestion du timer
    this.setAnswer();
    this.quiz.finished = true;
  }

  // ------------------ //
  // BOUTON 'JE VALIDE' //
  // ------------------ //
  validate() {
    // je valide vers EPROFIL
    // console.log('je valide vers EPROFIL');
    this.setAnswer();
    const datas = {
      idQuestion: parseInt(this.quiz.questions[this.quiz.index].id),
      responses: this.transformAnswersEprofil(false),
      dontknow: false,
      previous: false
    };
    // console.log(datas);
    window.parent.postMessage(['setResponse', datas], '*');
  }

  // --------------------------------- //
  // BOUTON 'JE NE SAIS PAS' (EPROFIL) //
  // --------------------------------- //
  dontknow() {
    this.setAnswer();
    const datas = {
      idQuestion: parseInt(this.quiz.questions[this.quiz.index].id),
      responses: this.transformAnswersEprofil(true),
      dontknow: true,
      previous: false
    };
    // console.log(datas);
    window.parent.postMessage(['setResponse', datas], '*');
  }

  // TODO descendre dans une classe parente pour tous les DE en heritage && descendre dans CO_lD
  setAnswer() {
    // Si le quiz n'est pas à l'état FINI et que nous ne sommes PAS en mode CORRECTION
    // if (!$scope.quiz.correction.started && !$scope.quiz.finished) {
    if (this.quiz.questions[this.quiz.index].type !== 'OR_GD_TE_RG_U' &&
      this.quiz.questions[this.quiz.index].type !== 'AS_GD_IM_IM_U' &&
      this.quiz.questions[this.quiz.index].type !== 'AS_GD_TE_IM_U' &&
      this.quiz.questions[this.quiz.index].type !== 'AS_GD_TE_TE_U' &&
      this.quiz.questions[this.quiz.index].type !== 'AS_GD_TE_TE_M' &&
      this.quiz.questions[this.quiz.index].type !== 'ET_GD_IM_PT_U' &&
      this.quiz.questions[this.quiz.index].type !== 'RE_CR_TE_PC_U' &&
      this.quiz.questions[this.quiz.index].type !== 'ET_GD_TE_PT_U' &&
      this.quiz.questions[this.quiz.index].type !== 'RE_CR_NO_PC_U' &&
      this.quiz.questions[this.quiz.index].type !== 'ET_CR_TE_PC_U'
    ) {
      let error = false;
      let answered = false;
      this.quiz.questions[this.quiz.index].answers = [];
      // Mise à jour du 'status' si question répondu dans la liste des questions
      // for (var i = 0; i < $scope.quiz.questions[$scope.quiz.index].propositions.length; i++) {
      const propositions = (Array.isArray(this.quiz.questions[this.quiz.index].propositions)) ? this.quiz.questions[this.quiz.index].propositions : [this.quiz.questions[this.quiz.index].propositions];
      for (const prop of propositions) {
        // for (var j = 0; j < $scope.quiz.questions[$scope.quiz.index].solutions.length; j++) {
        const solutions = (Array.isArray(this.quiz.questions[this.quiz.index].solutions)) ? this.quiz.questions[this.quiz.index].solutions : [this.quiz.questions[this.quiz.index].solutions];
        for (const sol of solutions) {
          if (prop.ordre === sol.ordre_proposition) {
            // on set l'état de cochage
            const checked = prop.checked ? 1 : 0;
            const sChecked = checked ? '1' : '0';
            if (sChecked !== sol.status) {
              error = true;
            }
            if (this.quiz.questions[this.quiz.index].type === 'CO_LD_LV_ID_U') {
              if (typeof prop.text !== 'undefined' && prop.text.trim().toUpperCase() !== sol.CDATA.trim().toUpperCase()) {
                error = true;
              }
            }
            const answer: Answer = new Answer();
            answer.order_proposition = prop.ordre;
            answer.order_cible = 0;
            answer.status = checked;
            answer.value = prop.text;
            answer.value_option = prop.value;
            this.quiz.questions[this.quiz.index].answers.push(answer);
            // On flag si réponse donnée par l'utilisateur (possibilité de faire précédent sans répondre en mode autonome
            if (prop.checked) {
              answered = true;
            }
            break;
          }
        }
      }
      if (!answered) {
        this.quiz.questions[this.quiz.index].status = 2;
      } else {
        this.quiz.questions[this.quiz.index].status = error ? 0 : 1;
      }
    }
  }

  // ---------------- //
  // MODAL CALCULATOR //
  // ---------------- //
  calculatorToggle() {
    if (this.calculatorState) {
      setTimeout(() => {
        this.calculatorState = false;
      }, 1000);
    } else {
      this.calculatorState = true;
    }
    // this.calculatorClass = (this.calculatorClass == 'bounceOutRight') ? 'bounceInRight' : 'bounceOutRight';
    // this.calculatorClass = (this.calculatorClass == 'bounceOutLeft') ? 'bounceInLeft' : 'bounceOutLeft';
    this.calculatorClass = (this.calculatorClass === 0) ? 0 : 1;
  }


  // ----------------- //
  // MODAL MEDIA IMAGE //
  // ----------------- //
  showMediaPreview(event, filename) {
    if (this.showMedia) {
      this.closeMediaPreview();
      return false;
    }
    filename = (typeof filename === 'undefined') ? event : filename;
    this.showMedia = true;
    if (this.config.mode === 'QUIZ') {
      this.imgSrc = this.config.quizUrl + this.quiz.folder + filename;
    } else if (this.config.mode.toUpperCase() === 'EPROFIL') {
      this.imgSrc = this.quiz.questionUrl + this.quiz.folder + filename;
    }
  }

  closeMediaPreview() {
    this.showMedia = false;
  }

  // -------------- //
  // IMAGE SRC ATTR //
  // -------------- //
  setImageSrc(type, data) {
    return this.utilsService.setImgSrc(type, data, this.config, this.quiz);
  }

  // dontknow efface les reponses selectionne par l'utilisateur si celui ci a repondu je ne sais pas
  transformAnswersEprofil(dontknow) {
    const arrResponses = [];
    for (const answer of this.quiz.questions[this.quiz.index].answers) {
      if (typeof this.dontknow !== 'undefined' && dontknow) {
        arrResponses.push('[' + answer.order_proposition + '|' + answer.order_cible + '|0|]');
      } else {
        if (this.quiz.questions[this.quiz.index].type === 'RE_CR_TE_PC_U' || this.quiz.questions[this.quiz.index].type === 'RE_CR_NO_PC_U') {
          arrResponses.push('[' + answer.order_proposition + '|' + answer.order_cible + '|' + answer.status + '|' + answer.value + '|]');
        } else if (this.quiz.questions[this.quiz.index].type === 'CO_LD_LV_ID_U') {
          arrResponses.push('[' + answer.order_proposition + '|' + answer.order_cible + '|' + answer.status + '|' + answer.value + '|' + answer.value_option + '|]');
        } else {
          arrResponses.push('[' + answer.order_proposition + '|' + answer.order_cible + '|' + answer.status + '|]');
        }
      }
    }
    // console.log(arrResponses);
    return arrResponses;
  }

  // ---------- //
  // MODAL INFO //
  // ---------- //


  @HostListener('touchstart', ['$event'])
  @HostListener('mousedown', ['$event'])
  mouseHandlingDown(event) {
    if (event.target.className === 'question' || event.target.className === 'label') {
      this.timerModal = 1;
      this.modal_open = false;
      this.timer_modal = setInterval(() => {
        this.timerModal += 1;
      }, 1000);
    }
  }

  @HostListener('mouseup', ['$event'])
  @HostListener('touchend', ['$event'])
  handleTouch(event) {
    if (event.target.className === 'question' || event.target.className === 'label') {
      if (this.timerModal >= 3 && !this.modal_open) {
        this.timerModal = 0;
        this.openModal();
        this.modal_open = true; // necessaire pour version tablette
        clearInterval(this.timer_modal);
        this.timer_modal = undefined;
      }
    }
  }

  // appel de la modal logo,instance,version
  @HostListener('window:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.keys.push(event.which);
    if (this.keys.toString().indexOf(this.konami) >= 0) {
      this.openModal();
      this.keys = [];
    }
    if (this.keys.length > 9) {
      this.keys = [];
    }
  }

  // ouvre la modal
  openModal() {
    const config: MatDialogConfig = new MatDialogConfig;
    /*config.data = 'Instance : ' + this.config.instance
     + '<br> Version : ' + this.config.version
     + '<br> Question : ' + this.quiz.questions[this.quiz.index].id;*/

    config.height = '300px';
    config.width = '600px';
    config.data = {
      message: '<br><div class="modal-version"> Version : ' + this.config.version + '</div>'
      + '<br><div class="modal-env"> Environnement de : ' + this.config.instance + '</div>'
      + '<br><div class="modal-question"> Question : ' + this.quiz.questions[this.quiz.index].id + '</div>',
      title: '<div class="modal-title">Navigateur Quiz HTML 5</div>'
    };

    this.dialog.open(ModalViewerComponent, config);
  }


}
