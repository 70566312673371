import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {DeComponent} from '../de/de.component';

@Component({
  selector: 'app-de-ic-im-pc',
  templateUrl: './de-ic-im-pc.component.html'
})
export class DeIcImPcComponent extends DeComponent implements OnInit, OnChanges {
  // recupere le quiz
  @Input() quiz: any;
  @Input() config: any;

  // envoi au parent la methode
  @Output()
  updStateProposition: EventEmitter<string> = new EventEmitter();
  @Output()
  showMediaPreview: EventEmitter<string> = new EventEmitter();
  sizeButton = '0px';
  sizeMax = 0;
  indexInit: number;

  ngOnInit() {
    this.sizeMax = (this.config.browser === 'ios' || this.config.browser === 'android') ? 250 : 300;
    this.setSizeButton();
    this.indexInit = this.quiz.questions[this.quiz.index].id;

    if (this.config.corrections) {
      // affiche la solution non la correction
      for (const solution in this.quiz.questions[this.quiz.index].solutions) {
        if (solution) {
          const propositions = (Array.isArray(this.quiz.questions[this.quiz.index].propositions)) ? this.quiz.questions[this.quiz.index].propositions : [this.quiz.questions[this.quiz.index].propositions];
          for (const prop of propositions) {
            console.log(prop.ordre, '=', this.quiz.questions[this.quiz.index].solutions[solution].ordre_proposition, '=>', this.quiz.questions[this.quiz.index].solutions[solution].status);
            if (prop.ordre === this.quiz.questions[this.quiz.index].solutions[solution].ordre_proposition && this.quiz.questions[this.quiz.index].solutions[solution].status.toString() === '1') {
              prop.checked = true;
              break;
            }
          }
        }
      }
    } else {
      if (typeof this.quiz.questions[this.quiz.index].answers !== 'undefined') {
        const answers = (Array.isArray(this.quiz.questions[this.quiz.index].answers)) ? this.quiz.questions[this.quiz.index].answers : [this.quiz.questions[this.quiz.index].answers];
        for (const answer of answers) {
          const propositions = (Array.isArray(this.quiz.questions[this.quiz.index].propositions)) ? this.quiz.questions[this.quiz.index].propositions : [this.quiz.questions[this.quiz.index].propositions];
          for (const prop of propositions) {
            if (prop.ordre === answer.order_proposition && answer.status.toString() === '1') {
              prop.checked = true;
              break;
            }
          }
        }
      }

      // si random et aucune reponse saisie
      let flag = false;
      if (typeof this.quiz.questions[this.quiz.index].proposition_random !== 'undefined' &&
        this.quiz.questions[this.quiz.index].proposition_random === '1'
      ) {
        for (const prop of this.quiz.questions[this.quiz.index].propositions) {
          if (prop['checked']) {
            flag = true;
            break;
          }
        }
        if (!flag) {
          this.utilsService.getShuffleProposition(this.quiz.questions[this.quiz.index].propositions);
        }
      }
    }
    super.updateNextButton();
  }

  setSizeButton() {
    if (this.quiz.questions[this.quiz.index].propositions.length < 4) {
      this.sizeButton = this.sizeMax + 'px';
    } else {
      this.sizeButton = ((document.getElementById('de-ic-im-pc').getBoundingClientRect().height / 2) < this.sizeMax) ? document.getElementById('de-ic-im-pc').getBoundingClientRect().height / 2 - 30 + 'px' : this.sizeMax + 'px';
    }
  }

  // ngDoCheck(): void {
  //   if(this.quiz.questions[this.quiz.index].id != this.indexInit) {
  //     this.indexInit = this.quiz.questions[this.quiz.index].id;
  //     this.setSizeButton();
  //   }
  // }

  ngOnChanges(changes: SimpleChanges) {
    if (this.currentIndex !== this.indexInit) {
      this.indexInit = this.currentIndex;
      this.setSizeButton();
    }
  }

  // mise a jour des proposition
  updStateProp(proposition) {
    this.updStateProposition.emit(proposition);
    super.updateNextButton();
  }

  // affiche la modal
  showMedia($event, filename) {
    this.showMediaPreview.emit(filename);
  }

  // recupere le src des images
  setImageSrc(type, data) {
    return this.utilsService.setImgSrc(type, data, this.config, this.quiz);
  }

  /*
  //dimensionne les boutons en fonction de la plus grande image
  //assigne le max size aux images
  setSizeButton(prop) {
    this.arrProp.push(prop);
    //si l'on enchaine deux question image le current n'est pas remis a zero
    if (this.cptMedia == 0) {
      this.sizeCurrent = 0;
      this.sizeImg = 0;
    }
    this.cptMedia++;

    //calcule la hauteur max de l'image et du bouton l'encadrant on prend la plus grande ne depassant pas le max
    if (document.getElementById(prop).getBoundingClientRect().height < this.sizeMax
      && document.getElementById(prop).getBoundingClientRect().height > this.sizeCurrent) {
      this.sizeCurrent = document.getElementById(prop).getBoundingClientRect().height + 20;
      this.sizeImg = document.getElementById(prop).getBoundingClientRect().height;
    }
    //permet de ne pas avoir trop d'ecart
    //ex si 7 images / 8 sont de hauteur superieur au max et que la 8eme ait une hauteur tres petites toutes vont se caler sur celle ci
    //en divisant par 2 les grandes images cela permet d'avoir un resultat correct
    let ratio = 1;
    if (document.getElementById(prop).getBoundingClientRect().height > this.sizeMax){
      ratio = this.sizeMax / document.getElementById(prop).getBoundingClientRect().height;
      if(document.getElementById(prop).getBoundingClientRect().height*ratio <= this.sizeMax){
        this.sizeImg = document.getElementById(prop).getBoundingClientRect().height *ratio;
        this.sizeCurrent = this.sizeImg + 20;
      }
    }

    this.sizeButton = this.sizeCurrent + 'px';
    this.sizeMaxImg = this.sizeImg + 'px';
    //appel a la derniere image pour calculer la taille max
    if(this.cptMedia == this.quiz.questions[this.quiz.index].propositions.length) {
      this.setMaxSizeImg();
    }
  }
  setMaxSizeImg(){
    for(let prop of this.arrProp){
      document.getElementById(prop).style.maxHeight = this.sizeMaxImg;
    }
    //lorsque l'on enchaine question du meme type on ne repasse pas par le oninit
    this.initValue();
  }

  initValue(){
    this.cptMedia = 0;
    this.arrProp = [];
  }*/
}
