import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Quiz} from '../../models/quiz';
import {Subscription} from 'rxjs';
import {ScreenService} from '../../services/service.screenConfig';

@Component({
  selector: 'app-button-previous',
  templateUrl: 'button-previous.component.html',
})
export class ButtonPreviousComponent implements OnInit {

  screenSubscription: Subscription;
  screenType: string;
  labelPrevious: string;

  // recupere le quiz
  @Input() quiz: Quiz;
  @Input() config: any;

  // envoi au parent la methode
  @Output()
  previous: EventEmitter<string> = new EventEmitter();

  constructor(private screenService: ScreenService) {
  }

  ngOnInit() {
    // BINDING widthscreen
    this.screenSubscription = this.screenService.screenType$.subscribe(screenType => {
      if (screenType) {
        this.screenType = screenType.toString();
      }
    });
    this.labelPrevious = (this.config.labelPrevious && this.config.labelPrevious !== '') ? this.config.labelPrevious : 'Question précédente';
  }

  previousQuestion() {
    this.previous.emit();
  }
}
