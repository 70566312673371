import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Config} from './models/config';

import {ConfigService} from './services/service.config';
import {PlatformWrapperService} from './services/service.platform-wrapper';
import {QuizService} from './services/service.quiz';
import {QuestionService} from './services/service.questions';
import {ScreenService} from './services/service.screenConfig';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit, OnDestroy {
  configSubscription: Subscription;
  quizDemandSubscription: Subscription;
  questionDemandSubscription: Subscription;
  config: Config;
  messageError: string;

  constructor(private configService: ConfigService,
              private quizService: QuizService,
              private questionService: QuestionService,
              private screenService: ScreenService,
              private platformWrapperService: PlatformWrapperService) {
  }

  ngOnInit() {
    // INIT CONFIG
    this.configService.get().then(() => {
      // Initialisation du WRAPPER + surcharge CONFIG
      this.platformWrapperService.wrapperInit();
    })
      .catch(error => {
        console.log(error);
      });

    // BINDING CONFIG
    this.configSubscription = this.configService.config$.subscribe(config => {
      if (config) {
        this.config = config;
      }
    });

    // BINDING RECEPTION QUIZ
    this.quizDemandSubscription = this.platformWrapperService.quiz$.subscribe(quiz => {
      if (quiz) {
        this.quizService.get(quiz).then(
          (quiz) => {
            // console.log(quiz);
          },
          (error) => {
            // TODO message erreur
            console.log(error);
            this.messageError = 'erreur chargement de la question :' + quiz;
          }
        );
      }
    });

    // BINDING RECEPTION QUESTION (MODE EPROFIL)
    this.questionDemandSubscription = this.platformWrapperService.question$.subscribe(question => {
      if (question) {
        // console.log('binding eprofil question->' + question);
        this.questionService.get(question).then(
          (datas) => {
            // console.log(datas);
          },
          (error) => {
            console.log(error);
            this.messageError = 'erreur chargement de la question :' + question;
          }
        );
      }
    });
    this.setWindow(window.innerWidth);
  }

  ngOnDestroy() {
    this.configSubscription.unsubscribe();
    this.quizDemandSubscription.unsubscribe();
    this.questionDemandSubscription.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setWindow(event.target.innerWidth);
  }

  setWindow(iWidth) {
    if (iWidth < 600) {
      this.screenService.setType('xs');
    } else if (iWidth > 599 && iWidth < 960) {
      this.screenService.setType('sm');
    } else if (iWidth > 959 && iWidth < 1280) {
      this.screenService.setType('md');
    } else if (iWidth > 1279 && iWidth < 1920) {
      this.screenService.setType('lg');
    } else if (iWidth > 1919) {
      this.screenService.setType('xl');
    }
  }

}
