import {Component, OnInit} from '@angular/core';
import {EtGdComponent} from '../et-gd/et-gd.component';

@Component({
  selector: 'app-et-gd-im-pt',
  templateUrl: './et-gd-im-pt.component.html',
  styleUrls: ['./et-gd-im-pt.component.css']
})
export class EtGdImPtComponent extends EtGdComponent implements OnInit {

  ngOnInit() {
    super.onInit('et-gd-im-pt');
  }

}
