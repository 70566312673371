import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-button-calculator',
  templateUrl: 'button-calculator.component.html',
})
export class ButtonCalculatorComponent implements OnInit {

  public ios: boolean;

  // envoi au parent la methode
  @Output()
  toggleCalculator: EventEmitter<string> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
    this.ios = /iPad|iPhone|iPod/.test(navigator.userAgent);
  }

  calculator() {
    this.toggleCalculator.emit();
  }
}
