import {Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, Output, SimpleChanges} from '@angular/core';
import {UtilsService} from '../../../services/service.utils';
import {DragulaService} from 'ng2-dragula';
import {Answer} from '../../../models/answer';
import {Subscription} from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-et-gd',
  template: ''
})
export class EtGdComponent implements OnDestroy, OnChanges {

  // recupere le quiz
  @Input() quiz: any;
  @Input() config: any;
  @Input() currentIndex: number;

  // envoi au parent la methode
  @Output()
  showMediaPreview: EventEmitter<string> = new EventEmitter();

  propNotChecked: any = [];
  propChecked: any = [];
  // dragSubscribe:any;
  dragSubscribe = new Subscription();
  // dropSubscribe: any;
  svg: any = {scale: 1};
  canvas: any = {};
  indexInit: number;
  type: string;

  constructor(protected utilsService: UtilsService, private dragulaService: DragulaService) {
    // BINDING DROP
    this.dragSubscribe.add(this.dragulaService.drop()
      .subscribe(({name, el, target, source, sibling}) => {
        // necessaire pour que le drag et drop fonctionne tjs
        if (source.id.indexOf('cibleRg') === 0) {
          document.getElementById(source.id).innerHTML = '';
        }
        if (target.id.indexOf('cibleRg') === 0) {
          // deplacement de propositon sur une cible on ajoute empty a la propositon liberee
          source.classList.add('empty');
        }
        if (target.id.indexOf('propositionDrag') === 0) {
          // deplacement une propositon sur une proposition vide on enleve la classe empty
          target.classList.remove('empty');
          // deplacement une propositon sur une proposition vide depuis proposition on ajoute la classe empty
          if (source.id.indexOf('propositionDrag') === 0) {
            source.classList.add('empty');
          }
        }
        this.setAnswer();
      }));
  }

  ngOnDestroy(): void {
    this.dragSubscribe.unsubscribe();
    // this.dropSubscribe.unsubscribe();
  }

  // hack ngOnChanges ne bind pas le changement
  // qd 2 et-gd se suive enchainement le composant n est pas detruit et on ne repasse pas dans le ngonint
  // ngDoCheck(): void {
  //   if (this.quiz.questions[this.quiz.index].id != this.indexInit) {
  //     this.indexInit = this.quiz.questions[this.quiz.index].id;
  //     this.onInit(this.type);
  //   }
  // }

  ngOnChanges(changes: SimpleChanges) {
    if (this.currentIndex !== this.indexInit) {
      this.indexInit = this.currentIndex;
      this.onInit(this.type);
    }
  }

  onInit(type) {
    // gestion proposition cible
    // si random et aucune reponse saisie
    let flagRandom = false;
    this.propNotChecked = Object.assign([], this.quiz.questions[this.quiz.index].propositions);

    if (this.config.corrections) {
      // affiche la solution non la correction
      this.propChecked = [];
      this.quiz.questions[this.quiz.index].solutions = (typeof this.quiz.questions[this.quiz.index].solutions.length === 'undefined') ? [this.quiz.questions[this.quiz.index].solutions] : this.quiz.questions[this.quiz.index].solutions;
      for (const solution in this.quiz.questions[this.quiz.index].solutions) {
        if (solution && this.quiz.questions[this.quiz.index].solutions[solution].status.toString() === '1') {
          // checked les cibles occupees
          for (const cible of this.quiz.questions[this.quiz.index].cibles) {
            if (cible.ordre === this.quiz.questions[this.quiz.index].solutions[solution].ordre_cible) {
              cible.checked = true;
              break;
            }
          }
          for (const propositionNotChecked in this.propNotChecked) {
            if (this.quiz.questions[this.quiz.index].solutions[solution].ordre_proposition === this.propNotChecked[propositionNotChecked].ordre) {
              this.propNotChecked[propositionNotChecked].ordreCible = this.quiz.questions[this.quiz.index].solutions[solution].ordre_cible;
              this.propChecked.push(this.propNotChecked[propositionNotChecked]);
              this.propNotChecked.splice(propositionNotChecked, 1);
              this.propNotChecked.push({empty: true});
              break;
            }
          }
        }
      }
    } else {

      if (typeof this.quiz.questions[this.quiz.index].answers === 'undefined') {
        if (typeof this.quiz.questions[this.quiz.index].proposition_random !== 'undefined' &&
          this.quiz.questions[this.quiz.index].proposition_random === '1'
        ) {
          flagRandom = true;
        }
        if (flagRandom) {
          this.utilsService.getShuffleProposition(this.quiz.questions[this.quiz.index].propositions);
        }

        // si alphaNum et aucune reponse saisie
        let flagAlphaNum = false;
        if (typeof this.quiz.questions[this.quiz.index].proposition_alpha_num !== 'undefined' &&
          this.quiz.questions[this.quiz.index].proposition_alpha_num === '1'
        ) {
          flagAlphaNum = true;
        }
        if (flagAlphaNum) {
          this.utilsService.getSortAlphaNum(this.quiz.questions[this.quiz.index].propositions);
        }
      } else {
        // si reponse saisie
        this.propChecked = [];
        for (const answer of this.quiz.questions[this.quiz.index].answers) {
          if (answer.status.toString() === '1') {
            // checked les cibles occupees
            for (const cible of this.quiz.questions[this.quiz.index].cibles) {
              if (cible.ordre === answer.order_cible) {
                cible.checked = true;
                break;
              }
            }
            for (const propositionNotChecked in this.propNotChecked) {
              if (answer.order_proposition === this.propNotChecked[propositionNotChecked].ordre) {
                this.propNotChecked[propositionNotChecked].ordreCible = answer.order_cible;
                this.propChecked.push(this.propNotChecked[propositionNotChecked]);
                this.propNotChecked.splice(propositionNotChecked, 1);
                this.propNotChecked.push({empty: true});
                break;
              }
            }
          }
        }
        this.indexInit = this.quiz.questions[this.quiz.index].id;
        this.type = type;
      }
    }

    // gestion drag
    const bagCible: any = this.dragulaService.find('cibleRg');
    if (typeof bagCible !== 'undefined') {
      this.dragulaService.destroy('cibleRg');
    }
    this.dragulaService.createGroup('cibleRg', {
      accepts: (el: Element, target: Element, source: Element, sibling: Element): boolean => {
        // cas proposition sur cible libre
        if (target.id.indexOf('propositionDrag') === 0 && target.innerHTML.trim().length === 0) {
          return true;
        }
        // cas proposition sur cible libre
        if (target.id.indexOf('cibleRg') === 0 && target.innerHTML.trim().length === 0) {
          return true;
        } else {
          return false;
        }
      }
    });
    this.updateNextButton();
    this.setMedia();
  }

  // ------ //
  // MEDIAS //
  // ------ //
  setMedia() {
    this.svg.src = this.utilsService.setImgSrc('support', this.quiz.questions[this.quiz.index].medias[0], this.config, this.quiz);
    // cas particulier ie et ios
    if (/Trident/.test(navigator.userAgent) || /Edge/.test(navigator.userAgent) || this.config.browser === 'ios') {
      setTimeout(() => {
        this.onLoadMedia();
      }, 500);
    }
  }

  // au chargement du media on recupere la taille de l'image
  onLoadMedia() {
    const newImg = new Image();
    const oSvg = this.svg;
    newImg.onload = function () {
      oSvg.width_init = newImg.width;
      oSvg.width = newImg.width;
      oSvg.height_init = newImg.height;
      oSvg.height = oSvg.height_init;
      oSvg.propositionDisplay = false;
    };
    newImg.src = this.svg.src;
    setTimeout(() => {
      this.svgScale();
      this.positionCibles();
      this.svg.propositionDisplay = true;
    }, 100);
  }

  // on calcule le scale pour bien positionner les cibes
  svgScale() {
    const imgMediaWidth = document.getElementById('imgMedia').getBoundingClientRect().width;
    this.svg.scale = document.getElementById('imgMedia').getBoundingClientRect().width < this.svg.width_init ? imgMediaWidth / this.svg.width_init : 1;
    // hack fullscreen tout navigateur
    setTimeout(() => {
      this.drawLineCible();
    }, 50);
    this.svg.height = this.svg.height_init * this.svg.scale;
    this.svg.width = this.svg.width_init * this.svg.scale;
  }

  svgChangeScale() {
    this.svgScale();
  }

  // trace les lignes en direction des cibles
  drawLineCible() {
    // postion x0, y0 du media
    const boundCible = document.getElementById('cible').getBoundingClientRect();
    const x0 = (boundCible.width - document.getElementById('imgMedia').getBoundingClientRect().width) / 2;
    const y0 = (boundCible.height - document.getElementById('imgMedia').getBoundingClientRect().height) / 2;
    // met le canvas a la taille de la zone cible
    const canvas = <HTMLCanvasElement> document.getElementById('mycanvas');
    canvas.width = document.getElementById('cible').getBoundingClientRect().width;
    canvas.height = document.getElementById('cible').getBoundingClientRect().height;
    const context = canvas.getContext('2d');

    for (const cible of this.quiz.questions[this.quiz.index].cibles) {
      const boundCibleRg = document.getElementById('cibleRg' + cible.ordre).getBoundingClientRect();
      cible.x = cible.CDATA.split('x')[0] * this.svg.scale;
      cible.y = cible.CDATA.split('x')[1] * this.svg.scale;


      // if (screenfull.isFullscreen) {
      //   cible.y = cible.CDATA.split('x')[1] * this.svg.scale - 55;
      // }else{
        cible.y = cible.CDATA.split('x')[1] * this.svg.scale;
      // }

      const xCible = cible.posLeft ? boundCibleRg.left - boundCible.left + boundCibleRg.width : boundCibleRg.left - boundCible.left;
      const yCible = boundCibleRg.top - boundCible.top + (boundCibleRg.height / 2);
      context.beginPath();
      context.moveTo(xCible, yCible);
      context.lineTo(x0 + cible.x, y0 + cible.y);
      context.lineWidth = 3;
      context.strokeStyle = '#ff0000';
      context.stroke();
    }
  }

  // ordonne les cibles de gauche et droite
  positionCibles() {
    const posLeft = this.svg.width / 2;
    const posUp = this.svg.height / 2;
    // TRIER par coordonne plus petit a plus grand
    for (const cible of this.quiz.questions[this.quiz.index].cibles) {
      cible.posLeft = (cible.CDATA.split('x')[0] * this.svg.scale) < posLeft ? true : false;
      cible.posUp = (cible.CDATA.split('x')[1] * this.svg.scale) < posUp ? true : false;
      cible.x = cible.CDATA.split('x')[0];
      cible.y = cible.CDATA.split('x')[1];
    }

    const arrTriLeftUp = [];
    for (const cible of this.quiz.questions[this.quiz.index].cibles) {
      if (cible.posLeft && cible.posUp) {
        arrTriLeftUp.push(cible);
      }
    }
    arrTriLeftUp.sort(this.dynamicSort('y'));
    const arrTriLeftDown = [];
    for (const cible of this.quiz.questions[this.quiz.index].cibles) {
      if (cible.posLeft && !cible.posUp) {
        arrTriLeftDown.push(cible);
      }
    }
    arrTriLeftDown.sort(this.dynamicSort('y'));
    const arrTriRightUp = [];
    for (const cible of this.quiz.questions[this.quiz.index].cibles) {
      if (!cible.posLeft && cible.posUp) {
        arrTriRightUp.push(cible);
      }
    }
    arrTriRightUp.sort(this.dynamicSort('y'));
    const arrTriRightDown = [];
    for (const cible of this.quiz.questions[this.quiz.index].cibles) {
      if (!cible.posLeft && !cible.posUp) {
        arrTriRightDown.push(cible);
      }
    }
    // ordonne le tableau du plus au haut plus bas
    arrTriRightDown.sort(this.dynamicSort('y'));

    const arrConcat = arrTriLeftUp.concat(arrTriLeftDown).concat(arrTriRightUp).concat(arrTriRightDown);
    this.quiz.questions[this.quiz.index].cibles = arrConcat;
    setTimeout(() => {
      this.drawLineCible();
    }, 100);

  }

  // TODO si conserver deplacer dans utils
  // ordonne le tableau selon la propriete envoyée
  dynamicSort(property) {
    let sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    };
  }

  // affiche la modal
  showMedia($event, filename) {
    this.showMediaPreview.emit(filename);
  }

  // recupere le src des images
  setImageSrc(type, data) {
    data.filename = (data.filename) ? data.filename : data.src;
    return this.utilsService.setImgSrc(type, data, this.config, this.quiz);
  }

  // met a jour les reponses de l'utilisateur
  setAnswer() {
    // console.log(this.dragulaService.find('propositionRg2').drake.containers);
    let answered = false;
    this.quiz.questions[this.quiz.index].answers = [];
    const cibles = document.getElementsByClassName('cible-reception');
    let error = false;
    const arrCibleChecked = [];
    for (const prop of this.quiz.questions[this.quiz.index].propositions) {
      // parcours toutes les cibles
      for (let i = 0; i < cibles.length; i++) {
        if (cibles[i].id.indexOf('cibleRg') === 0) {
          const item = document.getElementById(cibles[i].id);
          const answer: Answer = new Answer();
          answer.order_cible = parseInt(item.getAttribute('ordre'));
          answer.status = 0;
          // verifie si une proposition est sur une cible
          if (item.innerHTML.indexOf('proposition-content') >= 0 &&
            prop.ordre === item.getElementsByClassName('proposition-content').item(0).getAttribute('prop_order')) {
            answer.order_proposition = prop.ordre;
            answer.status = 1;
            answered = true;
            arrCibleChecked.push(answer.order_cible);
          } else {
            // remove class checked
            answer.order_proposition = prop.ordre;
          }
          // verifie par rapport a la solution
          for (const sol of this.quiz.questions[this.quiz.index].solutions) {
            if (answer.order_proposition.toString() === sol.ordre_proposition.toString() && answer.order_cible.toString() === sol.ordre_cible.toString() && answer.status.toString() !== sol.status.toString()) {
              error = true;
            }
          }
          this.quiz.questions[this.quiz.index].answers.push(answer);
        }
      }
    }

    // checked les cibles occupees
    for (const cible of this.quiz.questions[this.quiz.index].cibles) {
      cible.checked = false;
      for (const indexChecked of arrCibleChecked) {
        if (cible.ordre === indexChecked.toString()) {
          cible.checked = true;
        }
      }
    }

    this.updateNextButton();
    if (!answered) {
      this.quiz.questions[this.quiz.index].status = 2;
    } else {
      this.quiz.questions[this.quiz.index].status = error ? 0 : 1;
    }
  }

  // si une proposition est posee
  updateNextButton() {
    let flag = false;
    if (typeof this.quiz.questions[this.quiz.index].answers !== 'undefined') {
      for (const ans of this.quiz.questions[this.quiz.index].answers) {
        if (ans.status.toString() === '1') {
          flag = true;
          break;
        }
      }
    }
    this.quiz.next = flag;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.svgChangeScale();
  }
}
