import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class ScreenService {

  public screenType: string;
  private _screenTypeSource = new BehaviorSubject<Object>(new Object);
  screenType$ = this._screenTypeSource.asObservable();

  constructor() {
  }

  setType(datas) {
    this.screenType = datas;
    this._screenTypeSource.next(this.screenType);
  }

}
