export class Answer {
  order_proposition: number;
  order_cible: number;
  status: number;
  value: string;
  value_option: string;

  constructor() {
    this.order_proposition = 0;
    this.order_cible = 0;
    this.status = 0;
    this.value = '';
    this.value_option = '';
  }
}
