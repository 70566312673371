import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {Config} from '../models/config';


@Injectable()
export class ConfigService {

  public config: any;
  private _configSource = new BehaviorSubject<Config>(new Config);
  config$ = this._configSource.asObservable();

  constructor(private http: HttpClient) {
    let apiUrl = '';
    const ocqUrl = '';
    const pathMedia = '';
    const questionUrl = '';
    switch (window.location.hostname.toString().toLowerCase()) {
      case 'http://dev-navigateur-quiz.virtualgnfa.com/':
        apiUrl = 'http://212.194.113.229:4710/'; // DEV
        // apiUrl = 'http://dev-navigateur-quiz.virtualgnfa.com/';
        break;
      case 'http://test-navigateur-quiz.virtualgnfa.com/':
        // apiUrl = 'http://212.194.113.228:4710/'; // TEST
        apiUrl = 'http://test-navigateur-quiz.virtualgnfa.com/';
        break;
      case 'https://preprod-navigateur-quiz.virtualgnfa.com/':
        // apiUrl = 'http://212.194.113.231:4710/'; // PREPROD
        apiUrl = 'https://preprod-navigateur-quiz.virtualgnfa.com/';
        break;
      case 'https://navigateur-quiz.virtualgnfa.com/':
        // apiUrl = 'http://92.103.87.6:4710/'; // PROD
        apiUrl = 'https://navigateur-quiz.virtualgnfa.com/';
        break;
      default:
    }

    this.config = new Config();
    this.config.apiUrl = apiUrl;
    this.config.ocqUrl = ocqUrl;
    this.config.pathMedias = pathMedia;
    this.config.questionUrl = questionUrl;
    // cas de test
    this.config.calc = 1;
    // this.config.corrections = 1;
    // this.config.help=1;
    // this.config.labelValidateNone="label du btn"; //label du bouton dontKnow
    // this.config.btnValidateNone=1; //affichage ou non du bouton je ne sais pas depuis eprofil btn_validateNone
  }

  get(): Promise<any> {
    const sUrl = this.config.apiUrl + 'api/config/';
    // return this.http.get('http://127.0.0.1:4710/config/')
    return this.http.get(sUrl)
      .toPromise()
      .then(response => {
        // console.log(response);
        for (const key in response) {
          if (response[key]) {
            this.config[key] = response[key];
          }
        }
      })
      .catch(this.handleError);
  }

  set(datas) {
    for (const key in datas) {
      if (datas.hasOwnProperty(key)) {
        // remplace xx_xx par xxXx
        let KeyName = '';
        if (key.indexOf('_') > 0) {
          const maj = key.charAt(key.indexOf('_') + 1).toUpperCase();
          KeyName = key.substring(0, key.indexOf('_')) + maj + key.substring(key.indexOf('_') + 2, key.length);
        } else {
          KeyName = key;
        }
        this.config[KeyName] = datas[key];
      }
    }
    // console.log(this.config);
    this._configSource.next(this.config);
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }
}
