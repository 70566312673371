import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {UtilsService} from '../../../services/service.utils';
import 'hammerjs';
import {Answer} from '../../../models/answer';
import {Config} from '../../../models/config';

@Component({
  selector: 'app-re-cr-no-pc',
  templateUrl: './re-cr-no-pc.component.html'
})
export class ReCrNoPcComponent implements OnInit, OnChanges {
  @Output()
  showMediaPreview: EventEmitter<string> = new EventEmitter();

  // recupere le quiz
  @Input() quiz: any;
  @Input() config: Config;
  @Input() currentIndex: number;

  aSliders: any;
  indexInit: number;

  constructor(private utilsService: UtilsService) {
  }

  ngOnInit() {
    this.initSlider();
    this.indexInit = this.quiz.questions[this.quiz.index].id;
  }

  // ngDoCheck(): void {
  //   if (this.quiz.questions[this.quiz.index].id != this.indexInit) {
  //     this.indexInit = this.quiz.questions[this.quiz.index].id;
  //     this.ngOnInit();
  //   }
  // }

  ngOnChanges(changes: SimpleChanges) {
    if (this.currentIndex !== this.indexInit) {
      this.indexInit = this.currentIndex;
      this.ngOnInit();
    }
  }

  initSlider() {
    this.aSliders = [];
    let flagAnswer = true;
    const propositions = (Array.isArray(this.quiz.questions[this.quiz.index].propositions)) ? this.quiz.questions[this.quiz.index].propositions : [this.quiz.questions[this.quiz.index].propositions];
    if (typeof this.quiz.questions[this.quiz.index].answers !== 'undefined') {
      this.updateNextButton();
      if (this.quiz.questions[this.quiz.index].answers.length > 0) {
        flagAnswer = false;
      }
      this.quiz.questions[this.quiz.index].answers = (Array.isArray(this.quiz.questions[this.quiz.index].answers)) ? this.quiz.questions[this.quiz.index].answers : [this.quiz.questions[this.quiz.index].answers];
    } else {
      this.quiz.questions[this.quiz.index].answers = [];
      for (const prop of propositions) {
        const ans: Answer = new Answer();
        ans.order_proposition = prop.ordre;
        ans.order_cible = 0;
        ans.status = 0;
        this.quiz.questions[this.quiz.index].answers.push(ans);
      }
    }
    // construction des sliders
    let solutions = (Array.isArray(this.quiz.questions[this.quiz.index].solutions)) ? this.quiz.questions[this.quiz.index].solutions : [this.quiz.questions[this.quiz.index].solutions];
    // si random on melange les propositions
    if (typeof this.quiz.questions[this.quiz.index].proposition_random !== 'undefined' &&
      this.quiz.questions[this.quiz.index].proposition_random === '1' && flagAnswer
    ) {
      solutions = this.utilsService.getShuffleProposition(solutions);
    }

    for (const slider of solutions) {
      const slide: any = {};
      for (const proposition of propositions) {
        if (slider.ordre_proposition === proposition.ordre) {
          slide.CDATA = proposition.CDATA;
          slide.order_proposition = proposition.ordre;
          break;
        }
      }
      for (const answer of this.quiz.questions[this.quiz.index].answers) {
        if (slider.ordre_proposition === answer.order_proposition) {
          slide.value = answer.value;
          break;
        }
      }
      const aSlide = slider.CDATA.split('##');
      slide.min = aSlide[1];
      slide.max = aSlide[2];
      slide.step = aSlide[3];
      this.aSliders.push(slide);
    }

    // si alphanum on trie les propositions
    if (typeof this.quiz.questions[this.quiz.index].proposition_alpha_num !== 'undefined' &&
      this.quiz.questions[this.quiz.index].proposition_alpha_num === '1'
    ) {
      this.aSliders = this.utilsService.getSortAlphaNum(this.aSliders);
    }
  }

  updateNextButton() {
    let flag = false;
    const answers = (Array.isArray(this.quiz.questions[this.quiz.index].answers)) ? this.quiz.questions[this.quiz.index].answers : [this.quiz.questions[this.quiz.index].answers];
    for (const ans of answers) {
      if (ans.value !== '') {
        flag = true;
        break;
      }
    }
    this.quiz.next = flag;
  }

  setAnswer(obj) {
    const objorder_proposition = (typeof obj.source !== 'undefined') ? obj.source._elementRef.nativeElement.id.split('_')[1] : obj.target.id.split('_')[1];
    const objValue = (typeof obj.source !== 'undefined') ? obj.value : obj.target.value;
    this.updateNextButton();
    const answered = false;
    let error = false;

    for (const answer of this.quiz.questions[this.quiz.index].answers) {
      if (answer.order_proposition === objorder_proposition) {
        answer.value = objValue;
        for (const slider of this.aSliders) {
          if (slider.order_proposition === objorder_proposition) {
            slider.value = objValue;
          }
        }
        answer.status = 1;
        break;
      }
    }

    this.quiz.questions[this.quiz.index].solutions = (typeof this.quiz.questions[this.quiz.index].solutions.length === 'undefined') ? [this.quiz.questions[this.quiz.index].solutions] : this.quiz.questions[this.quiz.index].solutions;
    // verifie par rapport a la solution
    for (const sol of this.quiz.questions[this.quiz.index].solutions) {
      for (const answer of this.quiz.questions[this.quiz.index].answers) {
        if ((answer.order_proposition.toString() === sol.ordre_proposition.toString()
          && answer.order_cible.toString() === sol.ordre_cible.toString()
          && answer.status.toString() === sol.status.toString()
          && answer.value.toString() !== sol.CDATA.split('##')[0].toString()
        ) || (
          answer.order_proposition.toString() === sol.ordre_proposition.toString()
          && answer.order_cible.toString() === sol.ordre_cible.toString()
          && answer.status.toString() !== sol.status.toString()
        )) {
          error = true;
        }
      }
    }

    this.updateNextButton();
    if (!answered) {
      this.quiz.questions[this.quiz.index].status = 2;
    } else {
      this.quiz.questions[this.quiz.index].status = error ? 0 : 1;
    }
  }

  // affiche la modal
  showMedia($event, filename) {
    this.showMediaPreview.emit(filename);
  }

  // recupere le src des images
  setImageSrc(type, data) {
    return this.utilsService.setImgSrc(type, data, this.config, this.quiz);
  }
}
