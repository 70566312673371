import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {UtilsService} from '../../../services/service.utils';
import {Answer} from '../../../models/answer';


@Component({
  selector: 'app-re-cr-te-pc',
  templateUrl: './re-cr-te-pc.component.html'
})
export class ReCrTePcComponent implements OnInit, OnChanges {

  // recupere le quiz
  @Input() quiz: any;
  @Input() config: any;
  @Input() currentIndex: number;
  // envoie au parent la methode
  @Output()
  showMediaPreview: EventEmitter<string> = new EventEmitter();
  indexInit: number;

  constructor(private utilsService: UtilsService) {
  }

  ngOnInit() {
    // si random et aucune reponse saisie
    this.indexInit = this.quiz.questions[this.quiz.index].id;
    this.quiz.questions[this.quiz.index].propositions = (Array.isArray(this.quiz.questions[this.quiz.index].propositions)) ? this.quiz.questions[this.quiz.index].propositions : [this.quiz.questions[this.quiz.index].propositions];

    let flagAnswer = true;
    if (typeof this.quiz.questions[this.quiz.index].answers !== 'undefined' && this.quiz.questions[this.quiz.index].answers.length > 0) {
      for (const answer of this.quiz.questions[this.quiz.index].answers) {
        flagAnswer = false;
        if (typeof answer.value_option === 'undefined' || answer.value_option === '') {
          for (const prop of this.quiz.questions[this.quiz.index].propositions) {
            if (answer.order_proposition === prop.ordre) {
              answer.value_option = prop.CDATA;
            }
          }
        }
      }
      // this.updateNextButton(0);
    } else {
      if (typeof this.quiz.questions[this.quiz.index].proposition_random !== 'undefined' &&
        this.quiz.questions[this.quiz.index].proposition_random === '1' && flagAnswer
      ) {
        this.utilsService.getShuffleProposition(this.quiz.questions[this.quiz.index].propositions);
      }
      // si alphanum on trie les propositions
      if (typeof this.quiz.questions[this.quiz.index].proposition_alpha_num !== 'undefined' &&
        this.quiz.questions[this.quiz.index].proposition_alpha_num === '1' && flagAnswer
      ) {
        this.utilsService.getSortAlphaNum(this.quiz.questions[this.quiz.index].propositions);
      }
      this.quiz.questions[this.quiz.index].answers = [];
      for (const prop of this.quiz.questions[this.quiz.index].propositions) {
        const answer: Answer = new Answer();
        answer.order_proposition = prop.ordre;
        answer.order_cible = 0;
        answer.status = 1;
        answer.value = '';
        answer.value_option = prop.CDATA;
        this.quiz.questions[this.quiz.index].answers.push(answer);
      }
    }
    this.updateNextButton();
  }

  // hack ngOnChanges ne bind pas le changement quiz ok pour quiz.id
  // qd 2 re-cr se suive le composant n est pas detruit et on ne repasse pas dans le ngonint
  // ngDoCheck(): void {
  //   if (this.quiz.questions[this.quiz.index].id != this.indexInit) {
  //     this.indexInit = this.quiz.questions[this.quiz.index].id;
  //     this.ngOnInit();
  //   }
  // }

  ngOnChanges(changes: SimpleChanges) {
    if (this.currentIndex !== this.indexInit) {
      this.indexInit = this.currentIndex;
      this.ngOnInit();
    }
  }

  // mise a jour des answers
  updStateAnswer(response, evt) {
    for (const answer of this.quiz.questions[this.quiz.index].answers) {
      if (answer.order_proposition === response.order_proposition) {
        answer.value = evt.target.value;
        break;
      }
    }
    this.updateNextButton();
  }

  // recupere le src des images
  setImageSrc(type, data) {
    return this.utilsService.setImgSrc(type, data, this.config, this.quiz);
  }

  // affiche la modal
  showMedia($event, filename) {
    this.showMediaPreview.emit(filename);
  }

  updateNextButton() {
    let flag = false;
    for (const answer in this.quiz.questions[this.quiz.index].answers) {
      if (this.quiz.questions[this.quiz.index].answers[answer].value.trim().length > 0) {
        flag = true;
        break;
      }
    }
    this.quiz.next = flag;
  }

}
