import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {DragulaService} from 'ng2-dragula';
import {UtilsService} from '../../../services/service.utils';
import {Answer} from '../../../models/answer';
import {Subscription} from 'rxjs/internal/Subscription';


@Component({
  selector: 'app-as-gd',
  template: ''
})
export class AsGdComponent implements OnDestroy {
  // recupere le quiz
  @Input() quiz: any;
  @Input() config: any;

  // envoi au parent la methode
  @Output()
  showMediaPreview: EventEmitter<string> = new EventEmitter();

  propNotChecked: any = [];
  propChecked: any = [];
  dragSubscribe = new Subscription();
  bPropSaisie: boolean;
  bTargetProp = false;

  constructor(protected utilsService: UtilsService,
              private dragulaService: DragulaService) {

    // cas particulier en multi
    this.dragSubscribe.add(this.dragulaService.over()
      .subscribe(({name, el, source}) => {
        if (this.quiz.questions[this.quiz.index].type.substr(-1) === 'M' && name === 'cibleRg' && typeof document.getElementsByName('copy')[0] !== 'undefined') {
          document.getElementsByName('copy')[0].removeAttribute('style');
          document.getElementsByName('copy')[0].removeAttribute('name');
        }
      })
    );

    // BINDING DROP
    this.dragSubscribe.add(this.dragulaService.drop()
      .subscribe(({name, el, target, source, sibling}) => {
        if (this.quiz.questions[this.quiz.index].type.substr(-1) === 'M' && this.bTargetProp) {
          this.bTargetProp = false;
          const nodes = document.getElementsByName('copy');
          for (let i = 0, len = nodes.length; i !== len; ++i) {
            nodes[0].parentNode.removeChild(nodes[0]);
          }
          document.getElementById(el.id).removeAttribute('style');
        } else if (this.quiz.questions[this.quiz.index].type.substr(-1) === 'U') {
          if (target.id.indexOf('cibleRg') === 0) {
            // deplacement de propositon sur une cible on ajoute empty a la propositon liberee
            source.classList.add('empty');
          }
          if (target.id.indexOf('propositionDrag') === 0) {
            // deplacement une propositon sur une proposition vide depuis cible on enleve la clkasse empty
            target.classList.remove('empty');
            // deplacement une propositon sur une proposition vide depuis proposition on ajoute la classe empty
            if (source.id.indexOf('propositionDrag') === 0) {
              source.classList.add('empty');
            }
          }
        }
        // document.getElementById(el.id).classList.remove(type);
        this.setAnswer();
      })
    );
  }

  ngOnDestroy(): void {
    this.dragSubscribe.unsubscribe();
    // this.dropSubscribe.unsubscribe();
    // this.overSubscribe.unsubscribe();
  }

  onInit(type = null) {
    // console.log(this.quiz.questions[this.quiz.index].propositions);
    this.propChecked = [];
    this.bPropSaisie = false;
    if (this.config.corrections) {
      // affiche la solution non la correction
      this.propNotChecked = Object.assign([], this.quiz.questions[this.quiz.index].propositions);
      this.quiz.questions[this.quiz.index].solutions = (typeof this.quiz.questions[this.quiz.index].solutions.length === 'undefined') ? [this.quiz.questions[this.quiz.index].solutions] : this.quiz.questions[this.quiz.index].solutions;
      for (const solution in this.quiz.questions[this.quiz.index].solutions) {
        if (solution && this.quiz.questions[this.quiz.index].solutions[solution].status.toString() === '1') {
          this.bPropSaisie = true;
          // checked les cibles occupees
          for (const cible of this.quiz.questions[this.quiz.index].cibles) {
            if (cible.ordre === this.quiz.questions[this.quiz.index].solutions[solution].ordre_cible) {
              cible.checked = true;
              break;
            }
          }
          for (const propositionNotChecked in this.propNotChecked) {
            if (this.propNotChecked.hasOwnProperty(propositionNotChecked) && this.quiz.questions[this.quiz.index].solutions[solution].ordre_proposition === this.propNotChecked[propositionNotChecked].ordre) {
              this.propChecked.push(Object.assign({}, this.propNotChecked[propositionNotChecked]));
              this.propChecked[this.propChecked.length - 1].ordreCible = this.quiz.questions[this.quiz.index].solutions[solution].ordre_cible;
              if (this.quiz.questions[this.quiz.index].type.substr(-1) === 'U') {
                this.propNotChecked.splice(propositionNotChecked, 1);
                this.propNotChecked.push({empty: true});
              }
              break;
            }
          }
        }
      }

    } else {
      // si reponse saisie on place les propositions
      this.propNotChecked = Object.assign([], this.quiz.questions[this.quiz.index].propositions);
      if (typeof this.quiz.questions[this.quiz.index].answers !== 'undefined') {
        for (const answer of this.quiz.questions[this.quiz.index].answers) {
          if (answer.status.toString() === '1') {
            this.bPropSaisie = true;
            // checked les cibles occupees
            for (const cible of this.quiz.questions[this.quiz.index].cibles) {
              if (cible.ordre === answer.order_cible) {
                cible.checked = true;
                break;
              }
            }
            for (const propositionNotChecked in this.propNotChecked) {
              if (this.propNotChecked.hasOwnProperty(propositionNotChecked) && answer.order_proposition === this.propNotChecked[propositionNotChecked].ordre) {
                this.propChecked.push(Object.assign({}, this.propNotChecked[propositionNotChecked]));
                this.propChecked[this.propChecked.length - 1].ordreCible = answer.order_cible;
                if (this.quiz.questions[this.quiz.index].type.substr(-1) === 'U') {
                  this.propNotChecked.splice(propositionNotChecked, 1);
                  this.propNotChecked.push({empty: true});
                }
                break;
              }
            }
          }
        }
      }

      if (this.config.edition && typeof this.quiz.questions[this.quiz.index].solutions !== 'undefined') {
        for (const solution of this.quiz.questions[this.quiz.index].solutions) {
          if (solution.status.toString() === '1') {
            this.bPropSaisie = true;
            // checked les cibles occupees
            for (const cible of this.quiz.questions[this.quiz.index].cibles) {
              if (cible.ordre === solution.ordre_cible) {
                cible.checked = true;
                break;
              }
            }
            for (const propositionNotChecked in this.propNotChecked) {
              if (solution.ordre_proposition === this.propNotChecked[propositionNotChecked].ordre) {
                this.propChecked.push(Object.assign({}, this.propNotChecked[propositionNotChecked]));
                this.propChecked[this.propChecked.length - 1].ordreCible = solution.ordre_cible;
                break;
              }
            }
          }
        }
      }

      const bCopy: boolean = this.quiz.questions[this.quiz.index].type.substr(-1) === 'U' ? false : true;
      const bagCible = this.dragulaService.find('cibleRg');

      if (typeof bagCible !== 'undefined') {
        this.dragulaService.destroy('cibleRg');
      }

      this.dragulaService.createGroup('cibleRg', {
        accepts: (el: Element, target: Element, source: Element, sibling: Element): boolean => {
          // accepte ou refuse les drop pour :
          // cas proposition non libre refus en unique
          if (target.id.indexOf('propositionDrag') === 0 && target.innerHTML.trim().indexOf('proposition') >= 0 && !bCopy) {
            return false;
          }
          // cas proposition en multiple
          if (bCopy) {
            if (target.id.indexOf('propositionDrag') === 0 && source.id.indexOf('propositionDrag') < 0) {
              this.bTargetProp = true;
              el.setAttribute('style', 'display:none');
              el.setAttribute('name', 'copy');
              return true;
            } else if (target.id.indexOf('propositionDrag') === 0 && source.id.indexOf('propositionDrag') >= 0) {
              this.bTargetProp = false;
              el.removeAttribute('style');
              el.removeAttribute('name');
              return false;
            }
          }

          // cas cible n'a pas de proposition ou proposition en transit alors drop possible
          if (target.id.indexOf('cibleRg') === 0 && target.innerHTML.trim().indexOf('proposition') >= 0 && target.innerHTML.trim().indexOf('gu-transit') < 0) {
            return false;
          } else {
            if (bCopy) {
              el.removeAttribute('style');
              el.removeAttribute('name');
            }
            return true;
          }
        },
        // drag + copy de l'element dragge
        copy: function (el, source) {
          // si en mode multiple et que le drag ne vient pas de proposition alors on copie
          if (bCopy && source.id.indexOf('cible') < 0) {
            return true;
          } else {
            return false;
          }
        }
      });
    }
    this.updateNextButton();
  }

  // affiche la modal
  showMedia($event, filename) {
    this.showMediaPreview.emit(filename);
  }

  // recupere le src des images
  setImageSrc(type, data) {
    data.filename = data.src;
    return this.utilsService.setImgSrc(type, data, this.config, this.quiz);
  }

  setAnswer() {
    //  console.log(this.dragulaService.find('propositionRg2').drake.containers);
    let answered = false;
    this.quiz.questions[this.quiz.index].answers = [];
    const cibles = document.getElementsByClassName('cible-reception');
    let error = false;
    const arrCibleChecked = [];
    const propositions = (Array.isArray(this.quiz.questions[this.quiz.index].propositions)) ? this.quiz.questions[this.quiz.index].propositions : [this.quiz.questions[this.quiz.index].propositions];

    for (const prop of propositions) {
      // parcours toutes les cibles

      for (let i = 0; i < cibles.length; i++) {
        if (cibles[i].id.indexOf('cibleRg') === 0) {
          const item = document.getElementById(cibles[i].id);
          const answer: Answer = new Answer();
          answer.order_cible = parseInt(item.getAttribute('ordre'));
          answer.status = 0;
          // verifie si une proposition est sur une cible
          if (item.innerHTML.indexOf('proposition-content') >= 0 &&
            prop.ordre === item.getElementsByClassName('proposition-content').item(0).getAttribute('prop_order')) {
            answer.order_proposition = prop.ordre;
            answer.status = 1;
            answered = true;
            arrCibleChecked.push(answer.order_cible);
          } else {
            // remove class checked
            answer.order_proposition = prop.ordre;
          }
          // verifie par rapport a la solution
          for (const sol of this.quiz.questions[this.quiz.index].solutions) {
            if (answer.order_proposition.toString() === sol.ordre_proposition.toString() && answer.order_cible.toString() === sol.ordre_cible.toString() && answer.status.toString() !== sol.status.toString()) {
              error = true;
            }
          }
          this.quiz.questions[this.quiz.index].answers.push(answer);
        }
      }
    }
    // checked les cibles occupees
    for (const cible of this.quiz.questions[this.quiz.index].cibles) {
      cible.checked = false;
      for (const indexChecked of arrCibleChecked) {
        if (cible.ordre === indexChecked.toString()) {
          cible.checked = true;
        }
      }
    }
    this.updateNextButton();
    if (!answered) {
      this.quiz.questions[this.quiz.index].status = 2;
    } else {
      this.quiz.questions[this.quiz.index].status = error ? 0 : 1;
    }
  }

  // si une proposition est posee
  updateNextButton() {
    let flag = false;
    if (typeof this.quiz.questions[this.quiz.index].answers !== 'undefined') {
      for (const ans of this.quiz.questions[this.quiz.index].answers) {
        if (ans.status.toString() === '1') {
          flag = true;
          break;
        }
      }
    }
    this.quiz.next = flag;
  }

}
